<template>
  <nav-bar
    :type="type"
    position="absolute"
    collapse-id="navbarSupportedContent"
    :color-on-scroll="colorOnScroll"
    :show-navbar="showNavbar"
  >
    <div class="navbar-wrapper ps-3">
      <div class="navbar-toggle" :class="{ toggled: $sidebar.showSidebar }">
        <nav-bar-toggle-button @click="toggleSidebar"> </nav-bar-toggle-button>
      </div>
      <a class="navbar-brand" href="#pablo">
        {{ $route.name }}
      </a>
    </div>

    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navigation-index"
      aria-expanded="false"
      aria-label="Toggle navigation"
      @click="toggleNavbar"
    >
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>

    <template #navbar-menu>
      <ul id="navbar-top" class="navbar-nav">
        <li class="nav-item">
          <router-link class="nav-link" title="Status" to="/status">
            <font-awesome-icon
              class="fa-icon-only"
              icon="wave-square"
              fixed-width
            ></font-awesome-icon>

            <span class="d-lg-none d-md-block">Status</span>
          </router-link>
        </li>
        <drop-down
          tag="li"
          position="right"
          class="nav-item"
          icon="fa-icon-only"
          fa-library="fas"
          fa-icon="globe-europe"
          title="Language"
          nav-link
        >
          <a class="dropdown-item" href="#">English</a>
          <a class="dropdown-item" href="#">Nederlands</a>
        </drop-down>

        <li class="nav-item">
          <a class="nav-link" title="Sign out" @click="signOut()">
            <font-awesome-icon
              class="fa-icon-only"
              icon="sign-out-alt"
              fixed-width
            ></font-awesome-icon>

            <span class="d-lg-none d-md-block">Sign out</span>
          </a>
        </li>
      </ul>
    </template>
  </nav-bar>
</template>
<script>
import NavBar from "@/components/NavBar/NavBar";
import NavBarToggleButton from "@/components/NavBar/NavBarToggleButton";

const PATH_TO_HOME_ROUTE = "/";

export default {
  name: "TopNavbar",
  components: {
    NavBar,
    NavBarToggleButton
  },
  props: {
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info"
        ].includes(value);
      }
    },
    transparent: Boolean,
    colorOnScroll: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false
    };
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  methods: {
    async signOut() {
      await this.$auth.signOut();
      // Navigate back to home
      this.$router.push({ path: PATH_TO_HOME_ROUTE });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    }
  }
};
</script>
<style></style>
