<template>
  <div id="register" class="container">
    <div class="row justify-content-center">
      <div class="col-xxl-4 col-xl-5 col-lg-6 col-md-7">
        <VeeForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
          <form @submit="handleSubmit($event, register)">
            <card-component
              class="card-signup"
              no-footer-line
              card-header-classes="text-center"
            >
              <template #header>
                <h4 class="card-title">Register with</h4>
                <div class="social">
                  <button class="btn btn-icon btn-round btn-apple">
                    <font-awesome-icon
                      :icon="['fab', 'apple']"
                      fixed-width
                    ></font-awesome-icon>
                  </button>
                  <button class="btn btn-icon btn-round btn-facebook">
                    <font-awesome-icon
                      :icon="['fab', 'facebook-f']"
                      fixed-width
                    ></font-awesome-icon>
                  </button>
                  <button class="btn btn-icon btn-round btn-linkedin">
                    <font-awesome-icon
                      :icon="['fab', 'linkedin-in']"
                      fixed-width
                    ></font-awesome-icon>
                  </button>
                  <button class="btn btn-icon btn-round btn-google">
                    <font-awesome-icon
                      :icon="['fab', 'google']"
                      fixed-width
                    ></font-awesome-icon>
                  </button>
                  <h5 class="card-description">or be classical</h5>
                </div>
              </template>

              <Field
                v-slot="{ field }"
                v-model="user.profile.firstName"
                name="firstName"
              >
                <basic-input
                  v-bind="field"
                  name="firstName"
                  type="text"
                  placeholder="First Name..."
                  addon-left-icon="fa-icon fa-fw"
                  fa-icon="signature"
                  fa-library="fas"
                />
              </Field>
              <Field
                v-slot="{ field }"
                v-model="user.profile.lastName"
                name="lastName"
              >
                <basic-input
                  v-bind="field"
                  name="lastName"
                  type="text"
                  placeholder="Last Name..."
                  addon-left-icon="fa-icon fa-fw"
                  fa-icon="address-card"
                  fa-library="far"
                />
              </Field>
              <Field
                v-slot="{ field }"
                v-model="user.profile.email"
                name="email"
              >
                <basic-input
                  v-bind="field"
                  name="email"
                  type="text"
                  placeholder="Email..."
                  addon-left-icon="fa-icon fa-fw"
                  fa-icon="envelope"
                  fa-library="far"
                />
              </Field>
              <!-- Uncomment to create a user with credentials -->
              <!-- <Field
                  v-slot="{ field }"
                  v-model="user.credentials.password"
                  name="password"
                >
                  <basic-input
                    alternative
                    v-bind="field"
                    name="password"
                    type="password"
                    placeholder="Password"
                    addon-left-icon="fa-icon"
                    fa-library="fas"
                    fa-icon="unlock"
                  >
                  </basic-input>
                </Field> -->

              <basic-checkbox v-model="agree" class="text-start">
                I agree to the
                <a href="#something" @click.prevent="showTerms"
                  >terms and conditions</a
                >.
              </basic-checkbox>

              <template #footer>
                <basic-button
                  type="primary"
                  block
                  native-type="submit"
                  round
                  size="lg"
                >
                  Get Started
                </basic-button>
              </template>
            </card-component>
          </form>
        </VeeForm>
      </div>
    </div>
  </div>
</template>
<script>
// External plugins
import Swal from "sweetalert2";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

// Okta
import oktaApi from "@/api/oktaSdkApi";

// Form
import { Form as VeeForm, Field } from "vee-validate";
import { object, string } from "yup";

export default {
  name: "SignUp",
  components: {
    VeeForm,
    Field
  },
  setup() {
    const schema = object().shape({
      firstName: string().required().label("First name"),
      lastName: string().required().label("Last name"),
      email: string()
        .required("A valid email address is required")
        .email("A valid email address is required")
      // Uncomment to create a user with credentials
      // password: string()
      //   .min(
      //     8,
      //     "The password must be at least 8 characters and it must contain one of each: uppercase letter, lowercase letter, number & special character."
      //   )
      //   .matches(
      //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
      //     "The password must be at least 8 characters and it must contain one of each: uppercase letter, lowercase letter, number & special character."
      //   )
    });

    return {
      schema
    };
  },
  data() {
    return {
      user: {
        profile: {
          firstName: "",
          lastName: "",
          displayName: "",
          email: "",
          login: "",
          userType: "Customer"
        }
        // Uncomment to create a user with credentials
        // credentials: {
        //   password: ""
        // }
      },
      agree: false,
      terms: ""
    };
  },
  watch: {
    // Compute the login field based on the email value
    "user.profile.email": function () {
      this.user.profile.login = this.user.profile.email;
    },
    // Compute the displayName based on firstName + lastName
    "user.profile.firstName": function () {
      this.user.profile.displayName =
        this.user.profile.firstName + " " + this.user.profile.lastName;
    },
    "user.profile.lastName": function () {
      this.user.profile.displayName =
        this.user.profile.firstName + " " + this.user.profile.lastName;
    }
  },
  methods: {
    async register(values, actions) {
      // We create a user without password
      await oktaApi.createUser("activate=true", this.user).then((res) => {
        // Success
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Confirmation email send!",
            text: "You have received an email to confirm your account.",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-success btn-fill"
            }
          });
          // Error
        } else {
          Swal.fire({
            icon: "warning",
            title:
              "An error occured: " +
              res.status +
              " " +
              res.statusText +
              ". Please contact your administrator.",
            text: "Error message: " + res.data,
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-warning"
            }
          });
        }
      });
      // Reset the form after form submission
      actions.resetForm();
    },
    showTerms() {
      initScrollbar("terms");

      fetch("./terms.html")
        .then((response) => response.text())
        .then((data) => {
          this.terms = data;

          Swal.fire({
            title: "Terms & conditions",
            background: "#fdfdfd",
            html: `${this.terms}`,
            confirmButtonText: "I Agree &#128077; ",
            customClass: {
              confirmButton: "btn btn-primary btn-round btn-block"
            },
            buttonsStyling: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.agree = true;
            }
          });
        });
    }
  }
};
</script>
<style></style>
