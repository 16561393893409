import axios from "axios";
import qs from "qs";

const idnURL = process.env.IDN_CLIENT_API;

export default {
  async getAccessToken() {
    let accessToken = "";

    try {
      // Retrieve token from local storage and check if it's expired.
      // If it's expired, we obtain a new one.
      if (
        localStorage.getItem("idn-access-token") &&
        localStorage.getItem("idn-token-expires-at") > Date.now() / 1000
      ) {
        // Getting access token from local storage
        accessToken = localStorage.getItem("idn-access-token");
      } else {
        // No access token found or it has expired
        // Make post request to IDN API to obtain a new access token
        const token_url = idnURL + "/oauth/token";
        const data = qs.stringify({
          grant_type: "client_credentials",
          client_id: process.env.IDN_CLIENT_ID,
          client_secret: process.env.IDN_CLIENT_SECRET
        });

        await axios.post(token_url, data).then((res) => {
          // Store access token in localStorage
          localStorage.setItem("idn-access-token", res.data.access_token);
          localStorage.setItem(
            "idn-token-expires-at",
            Date.now() / 1000 + res.data.expires_in
          );
          accessToken = res.data.access_token;
        });
      }
    } catch (error) {
      //on fail, log the error in console and return nothing
      console.log(error);
      return;
    }
    return accessToken;
  }
};
