<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <suspense>
          <template #default>
            <claims-table />
          </template>
          <template #fallback>
            <loading-animation
              color="primary"
              icon-size="6x"
              fa-library="fas"
              fa-icon="spinner"
            >
            </loading-animation>
          </template>
        </suspense>
      </div>
    </div>
  </div>
</template>
<script>
// Vue components
import { defineAsyncComponent } from "vue";

export default {
  name: "UserProfile",
  components: {
    ClaimsTable: defineAsyncComponent(() =>
      import("./components/ClaimsTable.vue")
    )
  }
};
</script>
<style></style>
