// Breadcrumb
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb";
import BreadCrumbItem from "@/components/BreadCrumb/BreadCrumbItem";
import RouteBreadCrumb from "@/components/BreadCrumb/RouteBreadCrumb";

// Cards
import CardComponent from "@/components/Cards/CardComponent";
import StatsCard from "@/components/Cards/StatsCard";

// Inputs
import BasicCheckbox from "@/components/Inputs/BasicCheckbox";
import BasicRadio from "@/components/Inputs/BasicRadio";
import BasicInput from "@/components/Inputs/BasicInput";

// Navbar
import SidebarPlugin from "@/components/SideBarPlugin";

// Table Pagination
import TablePagination from "@/components/Table/TablePagination";

// Rest
import BasicButton from "@/components/BasicButton";
import DropDown from "@/components/DropDown";
import LoadingAnimation from "@/components/LoadingAnimation";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(app) {
    app.component("BasicButton", BasicButton);
    app.component("BasicCheckbox", BasicCheckbox);
    app.component("BasicInput", BasicInput);
    app.component("BasicRadio", BasicRadio);
    app.component("BreadCrumb", BreadCrumb);
    app.component("BreadCrumbItem", BreadCrumbItem);
    app.component("CardComponent", CardComponent);
    app.component("DropDown", DropDown);
    app.component("LoadingAnimation", LoadingAnimation);
    app.component("RouteBreadCrumb", RouteBreadCrumb);
    app.component("StatsCard", StatsCard);
    app.component("SidebarPlugin", SidebarPlugin);
    app.component("TablePagination", TablePagination);
  }
};

export default GlobalComponents;
