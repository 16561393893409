<template>
  <div
    id="toolbox-layout"
    class="wrapper"
    :class="{ 'nav-open': $sidebar.showSidebar }"
  >
    <side-bar data-color="default">
      <template #links>
        <user-menu></user-menu>
        <side-bar-item
          :link="{
            name: 'Toolbox',
            icon: 'fa-icon',
            faLibrary: 'fas',
            faIcon: 'chalkboard',
            path: '/toolbox'
          }"
        >
        </side-bar-item>

        <hr class="mt-2 mb-3 ms-3 ms-3 me-3 text-white opacity-50" />

        <li class="">
          <h6 class="nav-no-link ms-3 my-3 text-white opacity-75">
            <!-- <font-awesome-icon
              class="nav-no-link-icon fa-icon ms-2 me-2"
              :icon="['fas', 'o']"
            ></font-awesome-icon> -->
            <img
              class="vendor-icon"
              src="/img/vendors/okta-o-logo.webp"
              alt="Okta"
            />

            <p class="nav-no-link-label ms-1">Okta</p>
          </h6>
        </li>

        <side-bar-item
          :link="{
            name: 'Users',
            icon: 'fa-icon',
            faLibrary: 'fas',
            faIcon: 'user',
            path: '/users'
          }"
        ></side-bar-item>
        <side-bar-item
          :link="{
            name: 'Groups',
            icon: 'fa-icon',
            faLibrary: 'fas',
            faIcon: 'users',
            path: '/groups'
          }"
        ></side-bar-item>

        <hr class="my-3 ms-3 me-3 text-white opacity-50" />

        <li class="">
          <h6 class="nav-no-link ms-3 my-3 text-white opacity-75">
            <!-- <font-awesome-icon
              class="nav-no-link-icon fa-icon ms-2 me-2"
              :icon="['fas', 'sailboat']"
            ></font-awesome-icon> -->
            <img
              class="vendor-icon"
              src="/img/vendors/sailpoint-sail-logo.webp"
              alt="SailPoint"
            />

            <p class="nav-no-link-label ms-1">SailPoint</p>
          </h6>
        </li>

        <side-bar-item
          :link="{
            name: 'Roles',
            icon: 'fa-icon',
            faLibrary: 'fas',
            faIcon: 'image-portrait',
            path: '/roles'
          }"
        ></side-bar-item>
        <side-bar-item
          :link="{
            name: 'Access Profiles',
            icon: 'fa-icon',
            faLibrary: 'fas',
            faIcon: 'cubes',
            path: '/access-profiles'
          }"
        ></side-bar-item>
        <side-bar-item
          :link="{
            name: 'Tasks',
            icon: 'fa-icon',
            faLibrary: 'fas',
            faIcon: 'list-check',
            path: '/tasks'
          }"
        >
          <side-bar-item
            class="nav-link-mini"
            :link="{
              name: 'Pending',
              icon: 'fa-icon',
              faLibrary: 'fas',
              faIcon: 'list-check',
              path: '/tasks/pending'
            }"
          >
          </side-bar-item>
          <side-bar-item
            class="nav-link-mini"
            :link="{
              name: 'Completed',
              icon: 'fa-icon',
              faLibrary: 'fas',
              faIcon: 'list-check',
              path: '/tasks/completed'
            }"
          >
          </side-bar-item>
        </side-bar-item>
        <side-bar-item
          :link="{
            name: 'Access Requests',
            icon: 'fa-icon',
            faLibrary: 'fas',
            faIcon: 'business-time',
            path: '/access-requests'
          }"
        ></side-bar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <router-view name="header"></router-view>

      <router-view></router-view>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import TopNavbar from "@/views/Layout/Toolbox/TopNavbar.vue";
import ContentFooter from "@/views/Layout/Toolbox/ContentFooter.vue";
import UserMenu from "@/views/Layout/Toolbox/Menu/UserMenu.vue";
import SideBarItem from "@/components/SideBarPlugin/SideBarItem.vue";

export default {
  name: "ToolboxLayout",
  components: {
    TopNavbar,
    ContentFooter,
    UserMenu,
    SideBarItem
  },
  mounted() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar("sidebar");
      initScrollbar("sidebar-wrapper");
      initScrollbar("main-panel");

      docClasses.add("perfect-scrollbar-on");
    } else {
      docClasses.add("perfect-scrollbar-off");
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
<style lang="scss"></style>
