<template>
  <div class="loading" :class="`text-${color}`">
    <font-awesome-icon
      :icon="[`${faLibrary}`, `${faIcon}`]"
      :size="`${iconSize}`"
      spin
    >
    </font-awesome-icon>
    <div v-if="totalCount != 0" class="counter">
      <span>{{ currentCount }} of {{ totalCount }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoadingAnimation",
  props: {
    color: {
      type: String,
      default: "primary",
      description: "The color of the icon"
    },
    iconSize: {
      type: String,
      default: "",
      description: "The size of the icon"
    },
    faLibrary: {
      type: String,
      default: "",
      description: "The Font Awesome library that contains the desired icon"
    },
    faIcon: {
      type: String,
      default: "",
      description: "The name of the desired Font Awesome icon"
    },
    currentCount: {
      type: Number,
      default: 0
    },
    totalCount: {
      type: Number,
      default: 0
    }
  }
};
</script>
<style scoped>
.loading {
  position: absolute;
  top: 45%;
  left: 45%;
  z-index: 1000;
  width: auto;
}
.counter {
  position: absolute;
  top: 43%;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
}
</style>
