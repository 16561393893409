<template>
  <form id="user-permissions-form" @submit.prevent="updateUser">
    <card-component plain no-footer-line card-footer-classes="text-end">
      <template #default>
        <div class="row icon-checkbox-container mb-5">
          <div class="col-sm-4">
            <icon-checkbox
              v-model="design"
              icon="fa-icon"
              fa-icon="drafting-compass"
              fa-library="fas"
              title="Design"
            >
            </icon-checkbox>
          </div>
          <div class="col-sm-4">
            <icon-checkbox
              v-model="code"
              icon="fa-icon"
              fa-icon="terminal"
              fa-library="fas"
              title="code"
            >
            </icon-checkbox>
          </div>
          <div class="col-sm-4">
            <icon-checkbox
              v-model="develop"
              icon="fa-icon"
              fa-icon="mug-hot"
              fa-library="fas"
              title="Develop"
            >
            </icon-checkbox>
          </div>
        </div>
      </template>
      <template #footer>
        <basic-button native-type="submit" type="primary"
          >Save changes</basic-button
        >
      </template>
    </card-component>
  </form>
</template>
<script>
// COMPONENTS
import IconCheckbox from "@/components/Inputs/IconCheckbox";
// VUE INTERNAL
import { ref } from "vue";

export default {
  name: "UserEditPermissions",
  components: {
    IconCheckbox
  },
  props: {
    mode: {
      type: String,
      default: ""
    },
    initialUserProfile: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  emits: ["update-user-by-child"],
  setup(props) {
    const userProfile = ref(props.initialUserProfile);

    return {
      userProfile
    };
  },
  data() {
    return {
      code: false,
      design: false,
      develop: false
    };
  },
  mounted() {
    if (typeof this.initialUserProfile.roles !== "undefined") {
      this.code = this.initialUserProfile.roles.includes("code");
      this.design = this.initialUserProfile.roles.includes("design");
      this.develop = this.initialUserProfile.roles.includes("develop");
    }
  },
  methods: {
    async updateUser() {
      // Store the roles in an Array
      let roles = new Array();
      if (this.code) {
        roles.push("code");
      }
      if (this.design) {
        roles.push("design");
      }
      if (this.develop) {
        roles.push("develop");
      }
      // Update the model
      this.userProfile.roles = roles;
      this.$emit("update-user-by-child", this.userProfile);
    }
  }
};
</script>
<style></style>
