<template>
  <div class="content-with-header">
    <div class="panel-header panel-header-md">
      <div class="header text-center">
        <h2 class="title">{{ workItem.description }}</h2>
      </div>
    </div>
    <div id="task-edit-page" class="content">
      <div class="row">
        <!-- VIEW MODE -->
        <div class="col-12">
          <div class="row">
            <div class="col-md-4">
              <form>
                <card-component no-footer-line card-footer-classes="text-end">
                  <template #header>
                    <h4 class="card-title">Task Details</h4>
                  </template>
                  <template #default>
                    <div class="row">
                      <div class="col-md-12">
                        <label>ID</label>
                        <p class="form-control-static">{{ workItem.id }}</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <label>Description</label>
                        <p class="form-control-static">
                          {{ workItem.description }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label>Created</label>
                        <p class="form-control-static">
                          {{ workItem.created }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label>Owner</label>
                        <p class="form-control-static">
                          {{ workItem.ownerName }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label>State</label>
                        <p class="form-control-static">{{ workItem.state }}</p>
                      </div>
                    </div>
                  </template>
                </card-component>
              </form>
            </div>
            <div class="col-md-8">
              <form>
                <card-component no-footer-line card-footer-classes="text-end">
                  <template #header>
                    <h4 class="card-title">Activities</h4>
                  </template>
                  <template #default>
                    <div class="row">
                      <div class="col-md-12">
                        <label>ID</label>
                        <p class="form-control-static">{{ workItem.id }}</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <label>Description</label>
                        <p class="form-control-static">
                          {{ workItem.description }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label>Created</label>
                        <p class="form-control-static">{{ workItem.id }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label>ID</label>
                        <p class="form-control-static">{{ workItem.id }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label>ID</label>
                        <p class="form-control-static">{{ workItem.id }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label>ID</label>
                        <p class="form-control-static">{{ workItem.id }}</p>
                      </div>
                    </div>
                  </template>
                </card-component>
              </form>
            </div>
          </div>
        </div>
        <!-- VIEW MODE END -->
      </div>
    </div>
  </div>
</template>
<script>
// DATA & API
import identityNowApi from "@/api/identityNowApi.js";
// VUE INTERNAL
import { ref } from "vue";

export default {
  name: "TaskView",
  props: {
    workItemId: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const isLoading = ref(false);
    const error = ref(null);
    const workItem = ref([]);

    const getWorkItems = async () => {
      isLoading.value = true;
      try {
        await identityNowApi
          .getWorkItem(props.workItemId)
          .then((response) => (workItem.value = response.data));
        isLoading.value = false;
      } catch (e) {
        error.value = e;
      }
    };

    getWorkItems();

    return {
      workItem
    };
  }
};
</script>
<style></style>
