<template>
  <div class="row">
    <div class="col-md-4" style="min-height: 30vh">
      <VeeForm
        v-if="!isLoading"
        v-slot="{ handleSubmit }"
        :initial-values="group"
        :validation-schema="schema"
        as="div"
      >
        <form @submit="handleSubmit($event, onSubmit)">
          <card-component plain no-footer-line card-footer-classes="text-end">
            <template #header>
              <h5>Details</h5>
            </template>
            <template #default>
              <div class="row">
                <div class="col-12">
                  <basic-input
                    name="profile.name"
                    type="text"
                    label="Group Name *"
                    placeholder="Name"
                  >
                  </basic-input>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mb-4">
                  <label for="profile.description">Description</label>
                  <Field
                    name="profile.description"
                    as="textarea"
                    class="form-control"
                    placeholder="Describe the group..."
                  >
                  </Field>
                </div>
                <div class="category">* Required fields</div>
              </div>
            </template>
            <template #footer>
              <basic-button native-type="submit" type="primary"
                >Save changes</basic-button
              >
            </template>
          </card-component>
        </form>
      </VeeForm>
      <loading-animation
        v-else
        color="primary"
        icon-size="6x"
        fa-library="fas"
        fa-icon="spinner"
      >
      </loading-animation>
    </div>
  </div>
</template>
<script>
// Data
import oktaApi from "@/api/oktaClientApi.js";

// Vee-validate
import { Form as VeeForm, Field } from "vee-validate";
import { object, string } from "yup";

// Components
import Swal from "sweetalert2";

export default {
  name: "GroupEditDetails",
  components: {
    VeeForm,
    Field
  },
  props: {
    mode: {
      type: String,
      default: "create"
    },
    groupId: {
      type: String,
      default: ""
    }
  },
  emits: ["group-operation-by-child"],
  setup(values) {
    const schema = object().shape({
      profile: object({
        name: string().required().label("Name"),
        description: ""
      })
    });

    return {
      schema,
      values
    };
  },
  data() {
    return {
      group: {
        profile: {
          name: "",
          description: ""
        }
      },
      isLoading: false
    };
  },
  async created() {
    this.isLoading = true;
    if (this.mode === "edit") {
      await oktaApi
        .getGroup(this.groupId)
        .then((response) => (this.group = response));
    }
    this.isLoading = false;
  },
  methods: {
    async onSubmit(values) {
      try {
        // If in create mode, create a new group
        if (this.mode === "create") {
          await oktaApi
            .createGroup(values)
            .then((response) => {
              // Get the new group from the response
              this.group = response;
              // Store the new group Id and go to edit mode
              this.$localStorage.set("groupId", response.id);
              this.$localStorage.set("groupName", response.profile.name);
              this.$localStorage.set("mode", "edit");
              // Tell the parent there is a new group and to go to edit mode
              this.$emit(
                "group-operation-by-child",
                "edit",
                response.id,
                response.profile.name
              );
            })
            .then(
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: "You've added a new group.",
                customClass: {
                  confirmButton: "btn btn-success btn-fill"
                },
                buttonsStyling: false
              })
            );
        } else {
          // On submit, update group with new values
          Object.assign(this.group, values);

          await oktaApi.updateGroup(this.groupId, this.group).then(
            this.$emit(
              "group-operation-by-child",
              "edit",
              this.group.id,
              this.group.profile.name
            ),
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "You're changes were saved.",
              customClass: {
                confirmButton: "btn btn-success btn-fill"
              },
              buttonsStyling: false
            })
          );
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "Your changes could not be saved. Please contact your administrator. Message: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    }
  }
};
</script>
<style></style>
