<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card-component
          class="card-table"
          card-body-classes="table-full-width"
          no-footer-line
        >
          <template #header>
            <h4 class="card-title">Completed Tasks (Work Items)</h4>
          </template>
          <div>
            <div class="col-12 mb-1">
              <basic-button type="primary mt-0" @click="handleExport()">
                <font-awesome-icon
                  icon="file-export"
                  fixed-width
                ></font-awesome-icon>
                Export Tasks
              </basic-button>
            </div>
            <div class="table-searchbar d-flex flex-wrap">
              <div class="col-12">
                <label for="pagination">Show</label>
                <el-select
                  v-model="pagination.perPage"
                  class="select-white pagination"
                  name="pagination"
                >
                  <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
                <label> entries </label>

                <div class="float-end col-3">
                  <Field v-slot="{ field }" v-model="ownerId" name="ownerId">
                    <basic-input
                      v-bind="field"
                      name="ownerId"
                      type="text"
                      placeholder="Owner ID..."
                      addon-left-icon="fa-icon fa-fw"
                      fa-icon="search"
                      fa-library="fas"
                    />
                  </Field>
                </div>
              </div>
            </div>
            <el-table
              v-if="!isLoading"
              stripe
              style="width: 100%"
              :data="tableData"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :width="column.width"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="135" fixed="right" label="Actions">
                <template #default="props">
                  <div class="table-actions">
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="View"
                      placement="top-start"
                    >
                      <basic-button
                        class="view"
                        type="info"
                        size="sm"
                        round
                        icon
                        @click="handleView(props.$index, props.row)"
                      >
                        <font-awesome-icon
                          icon="eye"
                          fixed-width
                        ></font-awesome-icon>
                      </basic-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <loading-animation
              v-else
              color="primary"
              icon-size="6x"
              fa-library="fas"
              fa-icon="spinner"
              counter
              :current-count="completedCount"
              :total-count="totalCount"
            >
            </loading-animation>
          </div>
          <template #footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Showing {{ pagination.perPage }} entries
                </p>
              </div>
            </div>
          </template>
        </card-component>
      </div>
    </div>
  </div>
</template>
<script>
// VENDORS
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElTooltip
} from "element-plus";
import { Field } from "vee-validate";

// DATA
import identityNowApi from "@/api/identityNowApi.js";

// VUE INTERNAL
import { ref } from "vue";

export default {
  name: "CompletedTaskList",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    ElTooltip,
    Field
  },
  setup() {
    const isLoading = ref(false);
    const error = ref(null);
    const tableData = ref(null);
    const pagination = ref({
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100, 250]
    });
    const tableColumns = ref([
      {
        prop: "created",
        label: "Created",
        width: 200
      },
      {
        prop: "type",
        label: "Type",
        width: 180
      },
      {
        prop: "state",
        label: "State",
        width: 180
      },
      {
        prop: "description",
        label: "Description",
        width: 600
      }
    ]);
    const ownerId = ref("");
    const completedCount = ref(0);
    const totalCount = ref(0);

    const getTasks = async () => {
      isLoading.value = true;
      try {
        await identityNowApi
          .listCompletedWorkItems(
            pagination.value.perPage,
            0,
            false,
            ownerId.value
          )
          .then((response) => (tableData.value = response.data));
        isLoading.value = false;
      } catch (e) {
        error.value = e;
      }
    };

    getTasks();

    return {
      isLoading,
      error,
      tableData,
      getTasks,
      pagination,
      tableColumns,
      ownerId,
      completedCount,
      totalCount
    };
  },
  watch: {
    async "pagination.perPage"() {
      this.isLoading = true;
      await identityNowApi
        .listCompletedWorkItems(this.pagination.perPage, 0, false, this.ownerId)
        .then((response) => (this.tableData = response.data));
      this.isLoading = false;
    },
    ownerId() {
      this.isLoading = true;
      identityNowApi
        .listCompletedWorkItems(this.pagination.perPage, 0, false, this.ownerId)
        .then((response) => (this.tableData = response.data));
      this.isLoading = false;
    }
  },
  methods: {
    handleView(index, row) {
      this.$router.push({
        name: "View Task",
        params: { workItemId: `${row.id}` }
      });
    },
    async handleExport() {
      this.isLoading = true;
      await identityNowApi
        .exportWorkItems(this.pagination.perPage)
        .then((response) => (this.tableData = this.composeAPNames(response)));

      this.isLoading = false;
    }
  }
};
</script>
<style></style>
