<template>
  <div class="container">
    <div class="row justify-content-center">
      <div
        v-if="!authState || !authState.isAuthenticated"
        id="sign-in"
        class="col-xxl-4 col-xl-5 col-lg-6 col-md-7"
      >
        <!-- Okta widget -->
        <div id="okta-signin-container"></div>
      </div>
    </div>
  </div>
</template>
<script>
// Okta widget
import { oktaWidget } from "@/auth/oktaWidget";

export default {
  name: "SignIn",
  mounted() {
    this.$nextTick(function () {
      // Tell the widget where to go after successful authentication
      const originalUri = this.$auth.getOriginalUri();
      if (!originalUri) {
        this.$auth.setOriginalUri("/authenticated");
      }

      oktaWidget
        .showSignInToGetTokens({
          el: "#okta-signin-container"
        })
        .then((tokens) => {
          this.$auth.handleLoginRedirect(tokens);
        })
        .catch((err) => {
          throw err;
        });
    });
  },
  unmounted() {
    // Remove the widget from the DOM on path change
    oktaWidget.remove();
  },
  beforeMount() {
    if (this.authState && this.authState.isAuthenticated) {
      this.$router.push({
        name: "Authenticated"
      });
    }
  }
};
</script>
<style src="@/assets/css/okta-sign-in.min.css"></style>
