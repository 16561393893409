<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  data: function () {
    return { authenticated: false };
  },
  watch: {
    // Everytime the route changes, check for auth status
    $route: "isAuthenticated"
  },
  created() {
    this.isAuthenticated();
  },
  methods: {
    async isAuthenticated() {
      this.authenticated = await this.$auth.isAuthenticated();
    }
  }
};
</script>
