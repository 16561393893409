<template>
  <div class="content">
    <div class="row">
      <div id="change-password-form" class="col-lg-4 col-md-6">
        <VeeForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
          <form @submit="handleSubmit($event, changePassword)">
            <card-component no-footer-line card-footer-classes="text-end">
              <template #header>
                <h4 class="card-title">Change Password</h4>
              </template>
              <template #default>
                <h6>
                  <font-awesome-icon
                    :icon="['fas', 'key']"
                    fixed-width
                  ></font-awesome-icon>
                  Update your password
                </h6>

                <div class="row">
                  <div class="col-md-12">
                    <basic-input
                      name="passwordData.oldPassword"
                      type="password"
                      label="Old password"
                      placeholder="Your current password"
                      addon-left-icon="fa-icon"
                      fa-icon="key"
                      fa-library="fas"
                    >
                    </basic-input>
                    <basic-input
                      name="passwordData.newPassword"
                      type="password"
                      label="New password"
                      placeholder="Create a new password"
                      addon-left-icon="fa-icon"
                      fa-icon="user-secret"
                      fa-library="fas"
                    >
                    </basic-input>
                  </div>
                  <div class="col-md-12">
                    <basic-input
                      name="confirmation"
                      type="password"
                      label="Confirmation"
                      placeholder="Re-enter the password"
                      addon-left-icon="fa-icon"
                      fa-icon="redo"
                      fa-library="fas"
                    >
                    </basic-input>
                  </div>
                </div>
              </template>
              <template #footer>
                <basic-button native-type="submit" type="primary">
                  Update password
                </basic-button>
              </template>
            </card-component>
          </form>
        </VeeForm>
      </div>
    </div>
  </div>
</template>
<script>
// Data
import oktaApi from "@/api/oktaClientApi.js";

// Vee-validate
import { Form as VeeForm } from "vee-validate";
import { object, string, ref } from "yup";

// Components
import Swal from "sweetalert2";

export default {
  name: "UserPassword",
  components: {
    VeeForm
  },
  setup(values) {
    const schema = object().shape({
      passwordData: object({
        oldPassword: string().required("Please enter your current password"),
        newPassword: string().matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
          "The password must be at least 8 characters and it must contain one of each: uppercase letter, lowercase letter, number & special character."
        )
      }),
      confirmation: string().oneOf(
        [ref("passwordData.newPassword"), null],
        "Passwords must match"
      )
    });

    return {
      schema,
      values
    };
  },
  computed: {
    loggedInUser() {
      let oktaTokenStorage = JSON.parse(
        localStorage.getItem("okta-token-storage")
      );
      let loggedInUser = {};
      loggedInUser.id = oktaTokenStorage.idToken.claims.sub;
      return loggedInUser;
    }
  },
  methods: {
    async changePassword(values, { resetForm }) {
      try {
        await oktaApi.changePassword(this.loggedInUser.id, values.passwordData);

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "You're password was updated!",
          customClass: {
            confirmButton: "btn btn-success btn-fill"
          },
          buttonsStyling: false
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "Your changes could not be saved. Please contact your administrator. Message: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger"
          },
          buttonsStyling: false
        });
      }
      resetForm();
    }
  }
};
</script>
<style></style>
