<template>
  <card-component class="card-stats">
    <div class="statistics statistics-horizontal">
      <div class="info info-horizontal">
        <div class="row">
          <div v-if="$slots.icon || icon" class="col-5">
            <div class="icon icon-circle" :class="`icon-${type}`">
              <slot name="icon">
                <i :class="icon"></i>
              </slot>
            </div>
          </div>
          <div v-if="$slots.content || title" class="col-7 text-end">
            <slot>
              <h3 v-if="title" class="info-title">{{ title }}</h3>
              <h6 v-if="subTitle" class="stats-title">{{ subTitle }}</h6>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div v-if="$slots.footer" class="stats">
        <slot name="footer"></slot>
      </div>
    </template>
  </card-component>
</template>
<script>
export default {
  name: "StatsCard",
  props: {
    type: {
      type: String,
      default: "primary"
    },
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    }
  }
};
</script>
<style></style>
