<template>
  <simple-wizard>
    <template #header>
      <h3 class="card-title">Build your user</h3>
      <h5 class="fs-6 pt-1 pb-3">
        The information below is required to create the account.
      </h5>
    </template>

    <wizard-tab
      :before-change="() => validateStep('step1')"
      label="About"
      icon="user-circle"
      fa-icon="user-circle"
      fa-library="fas"
      iconclass="fa-lg wizard-icon"
    >
      <first-step ref="step1" @on-validated="onStepValidated"></first-step>
    </wizard-tab>

    <wizard-tab
      :before-change="() => validateStep('step2')"
      label="Role"
      icon="user-graduate"
      fa-icon="user-graduate"
      fa-library="fas"
      iconclass="fa-lg wizard-icon"
    >
      <second-step ref="step2" @on-validated="onStepValidated"></second-step>
    </wizard-tab>

    <wizard-tab
      :before-change="() => validateStep('step3')"
      label="Credentials"
      icon="key"
      fa-icon="key"
      fa-library="fas"
      iconclass="fa-lg wizard-icon"
    >
      <third-step
        ref="step3"
        :email="wizardModel.email"
        @on-validated="wizardComplete"
      ></third-step>
    </wizard-tab>
  </simple-wizard>
</template>
<script>
// Wizard
import SimpleWizard from "@/components/Wizard/SimpleWizard";
import WizardTab from "@/components/Wizard/WizardTab";

// Subcomponents
import FirstStep from "./UserCreateWizard/FirstStep.vue";
import SecondStep from "./UserCreateWizard/SecondStep.vue";
import ThirdStep from "./UserCreateWizard/ThirdStep.vue";

// External components
import Swal from "sweetalert2";

// Data
import oktaApi from "@/api/oktaClientApi.js";

export default {
  name: "UserCreateWizard",
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    SimpleWizard,
    WizardTab
  },
  emits: ["create-user-by-child"],
  data() {
    return {
      wizardModel: {},
      userToCreate: {
        profile: {},
        credentials: {}
      },
      user: {}
    };
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    async wizardComplete(validated, model) {
      // Copy wizard model to user object
      this.userToCreate.profile = this.wizardModel;
      this.userToCreate.credentials = model.credentials;

      try {
        if (model.activationMethod === "link") {
          // User is going to activate himself/herself
          await oktaApi.createActiveUser(this.userToCreate).then((response) => {
            // Get the new user from the response
            this.user = response;

            // Store the new user Id and go to edit mode
            this.$localStorage.set("mode", "edit");
            this.$localStorage.set("user", this.user.id);

            // Tell the parent there is a new user and to go to edit mode
            this.$emit("create-user-by-child", this.user);

            Swal.fire({
              title: "Success!",
              text: "The user was successfully created.",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-success"
              },
              buttonsStyling: false
            });
          });
        } else if (model.activationMethod === "password") {
          // Users password is set by the admin
          if (model.requirePasswordChange) {
            // The user needs to choose a new password on login
            await oktaApi
              .createActiveUserWithChangePassword(this.userToCreate)
              .then((response) => {
                // Get the new user from the response
                this.user = response;

                // Store the new user Id and go to edit mode
                this.$localStorage.set("mode", "edit");
                this.$localStorage.set("user", this.user.id);

                // Tell the parent there is a new user and to go to edit mode
                this.$emit("create-user-by-child", this.user);

                Swal.fire({
                  title: "Success!",
                  text: "The user was successfully created.",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-sucess"
                  },
                  buttonsStyling: false
                });
              });
          } else {
            // The user can keep using the password that's set by the admin
            await oktaApi
              .createActiveUser(this.userToCreate)
              .then((response) => {
                // Get the new user from the response
                this.user = response;

                // Store the new user Id and go to edit mode
                this.$localStorage.set("mode", "edit");
                this.$localStorage.set("user", this.user.id);

                // Tell the parent there is a new user and to go to edit mode
                this.$emit("create-user-by-child", this.user);

                Swal.fire({
                  title: "Success!",
                  text: "The user was successfully created.",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-sucess"
                  },
                  buttonsStyling: false
                });
              });
          }
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          customClass: {
            confirmButton: "btn btn-danger"
          },
          buttonsStyling: false,
          text:
            "The user could not be created. Please contact your administrator. Message: " +
            error
        });
      }
    }
  }
};
</script>
