import { createApp } from "vue";
// Import Okta auth setup
import { OktaAuth } from "@okta/okta-auth-js";
import OktaVue from "@okta/okta-vue";
// Import Vue app essentials
import App from "./App.vue";
import router from "./router";
// Import Vue app plugins
import ToolboxPlugins from "./plugins/toolboxPlugins";
import StoragePlugin from "vue-web-storage";

// Setup Okta Authentication SDK:
import oktaConfig from "@/auth/oktaConfig";
const oktaAuth = new OktaAuth(oktaConfig.oidc);

// Create app instance
const appInstance = createApp(App);

// plugin setup
appInstance.use(router);
appInstance.use(OktaVue, {
  oktaAuth,
  onAuthRequired: () => {
    router.push("/signin");
  },
  onAuthResume: () => {
    router.push("/signin");
  }
});
appInstance.use(ToolboxPlugins);
appInstance.use(StoragePlugin);

appInstance.mount("#app");
