<template>
  <div class="row">
    <div class="col-md-4" style="min-height: 30vh">
      <VeeForm
        v-if="!isLoading"
        v-slot="{ handleSubmit }"
        :initial-values="role"
        :validation-schema="schema"
        as="div"
      >
        <form @submit="handleSubmit($event, onSubmit)">
          <card-component plain no-footer-line card-footer-classes="text-end">
            <template #header>
              <h5>Role Details</h5>
            </template>
            <template #default>
              <div class="row">
                <div class="col-12">
                  <basic-input
                    name="name"
                    type="text"
                    label="Name *"
                    placeholder="Name"
                  >
                  </basic-input>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mb-4">
                  <label for="description">Description</label>
                  <Field
                    name="description"
                    as="textarea"
                    class="form-control"
                    placeholder="Describe the role..."
                  >
                  </Field>
                </div>
                <div class="category">* Required fields</div>
              </div>
            </template>
            <template #footer>
              <basic-button native-type="submit" type="primary"
                >Save changes</basic-button
              >
            </template>
          </card-component>
        </form>
      </VeeForm>
      <loading-animation
        v-else
        color="primary"
        icon-size="6x"
        fa-library="fas"
        fa-icon="spinner"
      >
      </loading-animation>
    </div>
    <div class="col-md-8">
      <div class="card card-plain">
        <div class="card-header"><h5>Results</h5></div>
        <div class="card-body"></div>
      </div>
    </div>
  </div>
</template>
<script>
// Data
import identityNowApi from "@/api/identityNowApi.js";

// Vee-validate
import { Form as VeeForm, Field } from "vee-validate";
import { object } from "yup";

// Components
import Swal from "sweetalert2";

export default {
  name: "RoleEditDetails",
  components: {
    VeeForm,
    Field
  },
  props: {
    mode: {
      type: String,
      default: "create"
    },
    roleId: {
      type: String,
      default: ""
    }
  },
  emits: ["role-operation-by-child"],
  setup(values) {
    const schema = object().shape({
      name: "",
      description: ""
    });

    return {
      schema,
      values
    };
  },
  data() {
    return {
      role: {
        name: "",
        description: ""
      },
      limit: 50,
      isLoading: false
    };
  },
  // async created() {
  //   this.isLoading = true;
  //   if (this.mode === "edit") {
  //     await identityNowApi
  //       .getGroup(this.groupId)
  //       .then((response) => (this.group = response));
  //   }
  //   this.isLoading = false;
  // },
  methods: {
    async onSubmit() {
      try {
        await identityNowApi.getRoles(this.limit).then((response) => {
          // Get the new role from the response
          // this.role = response;
          // Store the new group Id and go to edit mode
          console.log("Response data is: " + JSON.stringify(response.data));
          // this.$localStorage.set("accessToken", response.data.accessToken);
          // this.$localStorage.set("expires", response.data.expires);
          this.$localStorage.set("mode", "edit");
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "An error occurred. Please contact your administrator. Message: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    }
  }
};
</script>
<style></style>
