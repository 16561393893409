<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <!-- Card box -->
        <div class="card border-0 mb-0">
          <div class="card-header bg-transparent pt-4 pb-5">
            <div class="text-center mt-2 mb-4">
              Use the button below to test the API
            </div>
          </div>
          <div class="card-body px-lg-5 pt-5 pb-4">
            <div class="text-center">
              <basic-button
                type="warning"
                native-type="button"
                class="my-4"
                @click.prevent="apiTest()"
              >
                TEST API
              </basic-button>
            </div>
            <h4>Response:</h4>
            <pre><code>{{ res }}</code></pre>
          </div>
        </div>
        <!-- Card box -->
        <!-- Links below card -->
        <div class="row mt-3">
          <div class="col-6">
            <router-link to="/signin" class="text-light">
              <small>
                <font-awesome-icon :icon="['fas', 'arrow-left']" />
                Back to sign in
              </small>
            </router-link>
          </div>
        </div>
        <!-- Links below card -->
      </div>
    </div>
  </div>
</template>
<script>
// Okta
import oktaApi from "@/api/oktaSdkApi";

export default {
  name: "TestApi",
  data() {
    return {
      user: {
        profile: {
          firstName: "Thijm",
          lastName: "Duijve",
          displayName: "Thijm Duijve",
          email: "thijmen@thijmenduijve.nl",
          login: "thijmen@thijmenduijve.nl",
          userType: "Customer"
        },
        credentials: {
          password: "Wachtwoord123!"
        }
      },
      group: {
        profile: {
          name: "Testgroup",
          description: "Hello world."
        }
      },
      testVariables: {
        userId: "00u1r6zss5Xyq3rAz0x7",
        groupId: "dasd",
        changePassword: {
          oldPassword: "Wachtwoord123!",
          newPassword: "Wachtwoord1234!"
        },
        sendEmail: false
      },
      agree: false,
      registrationSuccessful: false,
      res: {}
    };
  },
  methods: {
    async register() {
      // OKTA SDK BACKEND
      await oktaApi
        .createUser("activate=true&nextLogin=changePassword", this.user)
        .then((res) => {
          console.log("Response is: " + JSON.stringify(res));
        });
    },
    async apiTest() {
      // GET USER
      // await oktaApi.getUser(this.testVariables.userId).then((res) => {
      //   console.log("Response data is: " + JSON.stringify(res.data));
      // });

      // GET USERS
      // await oktaApi.getUsers("q=Thijmen&limit=3").then((res) => {
      //   this.res = res;
      //   console.log("Response data is: " + JSON.stringify(res.data));
      // });

      // CREATE USER
      // await oktaApi
      //   .createUser("activate=true&nextLogin=changePassword", this.user)
      //   .then((res) => {
      //     console.log("Response data is: " + JSON.stringify(res.data));
      //   });

      // UPDATE USER
      // await oktaApi
      //   .updateUser(this.testVariables.userId, this.user)
      //   .then((res) => {
      //     console.log("Response data is: " + JSON.stringify(res.data));
      //   });

      // DELETE USER
      // await oktaApi.deleteUser(this.testVariables.userId, true).then((res) => {
      //   console.log("Response data is: " + JSON.stringify(res));
      // });

      // CHANGE PASSWORD
      // await oktaApi
      //   .changePassword(
      //     this.testVariables.userId,
      //     this.testVariables.changePassword
      //   )
      //   .then((res) => {
      //     console.log("Response data is: " + JSON.stringify(res.data));
      //   });

      // RESET PASSWORD
      // await oktaApi
      //   .resetPassword(this.testVariables.userId, this.testVariables.sendEmail)
      //   .then((res) => {
      //     console.log("Response data is: " + JSON.stringify(res.data));
      //   });

      // RESET FACTORS
      // await oktaApi.resetFactors(this.testVariables.userId).then((res) => {
      //   console.log("Response is: " + JSON.stringify(res));
      // });

      // SUSPEND/UNSUSPEND USER
      // await oktaApi.suspendUser(this.testVariables.userId).then((res) => {
      //   console.log("Response is: " + JSON.stringify(res));
      // });
      // await oktaApi.unsuspendUser(this.testVariables.userId).then((res) => {
      //   console.log("Response is: " + JSON.stringify(res));
      // });

      // ACTIVATE/DEACTIVATE/REACTIVATE USER
      // await oktaApi
      //   .activateUser(this.testVariables.userId, true)
      //   .then((res) => {
      //     this.res = JSON.stringify(res, null, 4);
      //     console.log("Response is: " + JSON.stringify(res));
      //   });
      // await oktaApi
      //   .deactivateUser(this.testVariables.userId, true)
      //   .then((res) => {
      //     this.res = JSON.stringify(res, null, 4);
      //     console.log("Response is: " + JSON.stringify(res));
      //   });
      // await oktaApi
      //   .reactivateUser(this.testVariables.userId, true)
      //   .then((res) => {
      //     this.res = JSON.stringify(res, null, 4);
      //     console.log("Response is: " + JSON.stringify(res));
      //   });

      // GET GROUP
      // await oktaApi.getGroup(this.testVariables.groupId).then((res) => {
      //   this.res = JSON.stringify(res, null, 4);
      //   console.log("Response is: " + JSON.stringify(res));
      // });
      // GET GROUPS
      await oktaApi.getGroups().then((res) => {
        this.res = JSON.stringify(res, null, 4);
        console.log("Response is: " + JSON.stringify(res));
      });
      // CREATE GROUPS
      // await oktaApi.createGroup(this.group).then((res) => {
      //   this.res = JSON.stringify(res, null, 4);
      //   console.log("Response is: " + JSON.stringify(res));
      // });
      // UPDATE GROUP
      // await oktaApi
      //   .updateGroup(this.testVariables.groupId, this.group)
      //   .then((res) => {
      //     this.res = JSON.stringify(res, null, 4);
      //     console.log("Response is: " + JSON.stringify(res));
      //   });
      // DELETE GROUP
      // await oktaApi.deleteGroup(this.testVariables.groupId).then((res) => {
      //   this.res = JSON.stringify(res, null, 4);
      //   console.log("Response is: " + JSON.stringify(res));
      // });

      // LIST GROUP USERS
      // await oktaApi
      //   .listGroupUsers(this.testVariables.groupId, "limit=1")
      //   .then((res) => {
      //     this.res = JSON.stringify(res, null, 4);
      //     console.log("Response is: " + JSON.stringify(res));
      //   });
      // ADD USER TO GROUP
      // await oktaApi
      //   .addUserToGroup(this.testVariables.groupId, this.testVariables.userId)
      //   .then((res) => {
      //     this.res = JSON.stringify(res, null, 4);
      //     console.log("Response is: " + JSON.stringify(res));
      //   });
      // REMOVE USER FROM GROUP
      // await oktaApi
      //   .removeUserFromGroup(
      //     this.testVariables.groupId,
      //     this.testVariables.userId
      //   )
      //   .then((res) => {
      //     this.res = JSON.stringify(res, null, 4);
      //     console.log("Response is: " + JSON.stringify(res));
      //   });
    }
  }
};
</script>
<style></style>
