import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleRight,
  faBookOpen,
  faBoxesPacking,
  faBrush,
  faBusinessTime,
  faCamera,
  faChalkboard,
  faCheck,
  faClipboardCheck,
  faCoins,
  faCompressAlt,
  faCubes,
  faDoorOpen,
  faDraftingCompass,
  faEye,
  faFileCircleMinus,
  faFileCsv,
  faFileExport,
  faFingerprint,
  faForward,
  faGavel,
  faGlobeEurope,
  faHandsHelping,
  faHeadset,
  faHome,
  faHourglassEnd,
  faHourglassStart,
  faIdCardAlt,
  faImagePortrait,
  faInfo,
  faKey,
  faListCheck,
  faMapMarkerAlt,
  faMugHot,
  faO,
  faPlayCircle,
  faPlus,
  faPowerOff,
  faPuzzlePiece,
  faQuestion,
  faRedo,
  faRunning,
  faSailboat,
  faSearch,
  faShareAlt,
  faSignature,
  faSignInAlt,
  faSignOutAlt,
  faSlidersH,
  faSpinner,
  faTerminal,
  faThumbtack,
  faTimes,
  faTrashAlt,
  faUnlockAlt,
  faUser,
  faUserCircle,
  faUserCog,
  faUserGraduate,
  faUserMinus,
  faUserPlus,
  faUsers,
  faUsersCog,
  faUserSecret,
  faUserTimes,
  faUnlock,
  faWaveSquare,
  faWrench
} from "@fortawesome/free-solid-svg-icons";
import {
  faApple,
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faGithub,
  faGoogle,
  faLinkedin,
  faLinkedinIn,
  faSlack,
  faTwitter,
  faUntappd
} from "@fortawesome/free-brands-svg-icons";
import {
  faAddressCard,
  faComment,
  faEdit,
  faEnvelope,
  faFileAlt,
  faHandshake,
  faIdBadge,
  faSmileBeam
} from "@fortawesome/free-regular-svg-icons";

const GlobalIcons = {
  install(app) {
    app.component("FontAwesomeIcon", FontAwesomeIcon);
    library.add(
      faAddressCard,
      faAngleRight,
      faApple,
      faBookOpen,
      faBoxesPacking,
      faBrush,
      faBusinessTime,
      faCamera,
      faChalkboard,
      faCheck,
      faClipboardCheck,
      faCoins,
      faComment,
      faCompressAlt,
      faCubes,
      faDoorOpen,
      faDraftingCompass,
      faEdit,
      faEnvelope,
      faEye,
      faFacebook,
      faFacebookF,
      faFacebookSquare,
      faFileAlt,
      faFileCsv,
      faFileCircleMinus,
      faFileExport,
      faFingerprint,
      faForward,
      faGavel,
      faGithub,
      faGlobeEurope,
      faHeadset,
      faGoogle,
      faHandsHelping,
      faHandshake,
      faHome,
      faHourglassEnd,
      faHourglassStart,
      faIdBadge,
      faIdCardAlt,
      faImagePortrait,
      faInfo,
      faKey,
      faLinkedin,
      faLinkedinIn,
      faListCheck,
      faMapMarkerAlt,
      faMugHot,
      faO,
      faPlayCircle,
      faPlus,
      faPowerOff,
      faPuzzlePiece,
      faQuestion,
      faRedo,
      faRunning,
      faSailboat,
      faSearch,
      faShareAlt,
      faSignature,
      faSignInAlt,
      faSignOutAlt,
      faSlack,
      faSlidersH,
      faSmileBeam,
      faSpinner,
      faTimes,
      faTerminal,
      faThumbtack,
      faTrashAlt,
      faTwitter,
      faUser,
      faUnlock,
      faUnlockAlt,
      faUntappd,
      faUserCircle,
      faUserCog,
      faUserGraduate,
      faUserMinus,
      faUserPlus,
      faUsers,
      faUsersCog,
      faUserSecret,
      faUserTimes,
      faWaveSquare,
      faWrench
    );
  }
};

export default GlobalIcons;
