<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card-component
          class="card-table"
          card-body-classes="table-full-width"
          no-footer-line
        >
          <template #header>
            <h4 class="card-title">Pending Tasks (Work Items)</h4>
          </template>
          <div>
            <div class="col-12 mb-1">
              <basic-button type="primary mt-0" @click="handleExport()">
                <font-awesome-icon
                  icon="file-export"
                  fixed-width
                ></font-awesome-icon>
                Export Tasks
              </basic-button>
              <basic-button type="default mt-0 ms-2" @click="completeTasks()">
                <font-awesome-icon
                  icon="clipboard-check"
                  fixed-width
                ></font-awesome-icon>
                Complete Tasks
              </basic-button>
            </div>
            <div class="table-searchbar d-flex flex-wrap">
              <div class="col-12">
                <label for="pagination">Show</label>
                <el-select
                  v-model="pagination.perPage"
                  class="select-white pagination"
                  name="pagination"
                >
                  <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
                <label> entries </label>

                <div class="float-end col-3">
                  <Field v-slot="{ field }" v-model="ownerId" name="ownerId">
                    <basic-input
                      v-bind="field"
                      name="ownerId"
                      type="text"
                      placeholder="Owner ID..."
                      addon-left-icon="fa-icon fa-fw"
                      fa-icon="search"
                      fa-library="fas"
                    />
                  </Field>
                </div>
              </div>
            </div>
            <el-table
              v-if="!isLoading"
              stripe
              table-layout="auto"
              :data="tableData"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :width="column.width"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="135" fixed="right" label="Actions">
                <template #default="props">
                  <div class="table-actions">
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="View"
                      placement="top-start"
                    >
                      <basic-button
                        class="view"
                        type="info"
                        size="sm"
                        round
                        icon
                        @click="handleView(props.$index, props.row)"
                      >
                        <font-awesome-icon
                          icon="eye"
                          fixed-width
                        ></font-awesome-icon>
                      </basic-button>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="Complete"
                      placement="top-start"
                    >
                      <basic-button
                        class="remove"
                        type="success"
                        size="sm"
                        round
                        icon
                        @click="
                          handleWorkItemCompletion(props.$index, props.row)
                        "
                      >
                        <font-awesome-icon
                          icon="check"
                          fixed-width
                        ></font-awesome-icon>
                      </basic-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <loading-animation
              v-else
              color="primary"
              icon-size="6x"
              fa-library="fas"
              fa-icon="spinner"
              :current-count="completedCount"
              :total-count="totalCount"
            >
            </loading-animation>
          </div>
          <template #footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Showing {{ pagination.perPage }} entries
                </p>
              </div>
              <table-pagination
                v-model="pagination.currentPage"
                class="pagination-no-border"
                :per-page="pagination.perPage"
                :total="pagination.total"
              >
              </table-pagination>
            </div>
          </template>
        </card-component>
      </div>
    </div>
  </div>
</template>
<script>
// VENDOR COMPONENTS
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElTooltip
} from "element-plus";
import { Field } from "vee-validate";
import Swal from "sweetalert2";

// DATA
import identityNowApi from "@/api/identityNowApi.js";

// VUE INTERNAL
import { ref } from "vue";

export default {
  name: "PendingTaskList",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    ElTooltip,
    Field
  },
  setup() {
    const isLoading = ref(false);
    const error = ref(null);
    const tableData = ref(null);
    const pagination = ref({
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100, 250],
      total: 500
    });
    const tableColumns = ref([
      {
        prop: "created",
        label: "Created",
        width: 170
      },
      {
        prop: "ownerName",
        label: "Owner Name",
        width: 180
      },
      {
        prop: "ownerId",
        label: "Owner ID",
        width: 290
      },
      {
        prop: "description",
        label: "Description",
        width: 600
      }
    ]);
    const ownerId = ref("");
    const completedCount = ref(0);
    const totalCount = ref(0);

    const getTasks = async () => {
      isLoading.value = true;
      try {
        await identityNowApi
          .listPendingWorkItems(
            pagination.value.perPage,
            0,
            false,
            ownerId.value
          )
          .then((response) => (tableData.value = response.data));
        isLoading.value = false;
      } catch (e) {
        error.value = e;
      }
    };

    getTasks();

    return {
      isLoading,
      error,
      tableData,
      getTasks,
      pagination,
      tableColumns,
      ownerId,
      completedCount,
      totalCount
    };
  },
  watch: {
    async "pagination.perPage"() {
      this.isLoading = true;
      await identityNowApi
        .listPendingWorkItems(this.pagination.perPage, 0, false, this.ownerId)
        .then((response) => (this.tableData = response.data));
      this.isLoading = false;
    },
    async ownerId() {
      this.isLoading = true;
      await identityNowApi
        .listPendingWorkItems(this.pagination.perPage, 0, false, this.ownerId)
        .then((response) => (this.tableData = response.data));
      this.isLoading = false;
    }
  },
  methods: {
    handleView(index, row) {
      this.$router.push({
        name: "View Task",
        params: { workItemId: `${row.id}` }
      });
    },
    handleWorkItemCompletion(index, row) {
      Swal.fire({
        title: "Are you sure?",
        text: `You're about to complete the task with description "${row.description}". You won't be able to revert this!`,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary btn-fill",
          cancelButton: "btn btn-default btn-fill"
        },
        confirmButtonText: "Yes, complete this task!",
        buttonsStyling: false
      }).then((result) => {
        // If confirmed, complete the task in IdentityNow and remove from table
        if (result.value) {
          this.completeWorkItem(`${row.id}`);
        }
      });
    },
    async completeWorkItem(id) {
      try {
        // Complete the task in IdentityNow
        await identityNowApi.completeWorkItem(id);
        // Remove the task from the table
        let indexToDelete = this.tableData.findIndex(
          (tableRow) => tableRow.id === id
        );
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1);
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "The task could not be deleted. Please contact your administrator. Message: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger"
          },
          buttonsStyling: false
        });
      }
    },
    async handleExport() {
      this.isLoading = true;
      await identityNowApi
        .exportWorkItems(this.pagination.perPage)
        .then((response) => (this.tableData = this.composeAPNames(response)));

      this.isLoading = false;
    },
    async completeTasks() {
      this.totalCount = this.tableData.length;

      Swal.fire({
        title: "Are you sure?",
        text:
          "You're about to complete the " +
          this.totalCount +
          " tasks shown on the screen. You won't be able to revert this!",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary btn-fill",
          cancelButton: "btn btn-default btn-fill"
        },
        confirmButtonText: "Yes, mark as complete!",
        buttonsStyling: false
      }).then(async (result) => {
        // If confirmed, complete the tasks on the screen
        if (result.value) {
          this.isLoading = true;

          this.completedCount = 0;

          for (const workItem of this.tableData) {
            console.log("Now completing work item: " + workItem.id);

            try {
              await identityNowApi.completeWorkItem(workItem.id);
            } catch (error) {
              console.log("Error occurred while completing. " + error);
            }

            this.completedCount++;
          }

          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Completed " + this.completedCount + " tasks.",
            customClass: {
              confirmButton: "btn btn-success btn-fill"
            },
            buttonsStyling: false
          });

          // After removal, force a table refresh by reinitiating the page count
          this.pagination.perPage = 10;
          // Reset counters
          this.completedCount = 0;
          this.totalCount = 0;

          this.isLoading = false;
        } else {
          // Reset counters
          this.completedCount = 0;
          this.totalCount = 0;
        }
      });
    }
  }
};
</script>
<style></style>
