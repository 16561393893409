<template>
  <bread-crumb>
    <bread-crumb-item
      v-for="(route, index) in $route.matched.slice()"
      :key="route.name"
      style="display: inline-block"
    >
      <router-link
        v-if="index < $route.matched.length - 1"
        :to="{ name: route.name }"
        class="breadcrumb-link"
      >
        {{ route.name }}
      </router-link>
      <span v-else class="breadcrumb-current">{{ route.name }}</span>
    </bread-crumb-item>
  </bread-crumb>
</template>

<script>
export default {
  name: "RouteBreadCrumb",
  props: {
    root: {
      type: String,
      default: "default"
    }
  },
  methods: {
    getBreadName(route) {
      return route.name;
    }
  }
};
</script>

<style scoped></style>
