<template>
  <div class="form-check" :class="[{ disabled: disabled }, inlineClass]">
    <label :for="cbId" class="form-check-label">
      <input
        :id="cbId"
        v-model="model"
        class="form-check-input"
        type="checkbox"
        :disabled="disabled"
      />
      <span class="form-check-sign">
        <font-awesome-icon
          v-if="modelValue == true"
          class="fa-valicon"
          :icon="['fas', 'check']"
        ></font-awesome-icon>
      </span>

      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>
<script>
export default {
  name: "BasicCheckbox",
  model: {
    prop: "modelValue"
  },
  props: {
    modelValue: {
      type: [Array, Boolean],
      default: undefined
    },
    disabled: Boolean,
    inline: Boolean,
    hasError: Boolean
  },
  emits: ["update:modelValue"],
  data() {
    return {
      cbId: "",
      touched: false
    };
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(check) {
        if (!this.touched) {
          this.touched = true;
        }
        this.$emit("update:modelValue", check);
      }
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
      return ``;
    }
  },
  created() {
    this.cbId = Math.random().toString(16).slice(2);
  }
};
</script>
