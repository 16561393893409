<template>
  <VeeForm ref="form" :validation-schema="schema" @submit="validate">
    <h5 class="info-text">How will the user be activated?</h5>
    <div class="row justify-content-center mt-3">
      <div class="col-lg-8">
        <div class="row">
          <div class="col-12">
            <el-radio-group v-model="model.activationMethod" class="block">
              <el-radio-button label="link"
                ><font-awesome-icon
                  :icon="['far', 'envelope']"
                ></font-awesome-icon>
                Activation link
              </el-radio-button>
              <el-radio-button label="password"
                ><font-awesome-icon
                  :icon="['fas', 'hands-helping']"
                ></font-awesome-icon>
                Set password
              </el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div
          v-if="model.activationMethod === 'password'"
          class="row justify-content-center"
        >
          <div class="col-md-8 mt-5">
            <p>Create a password that can be used to login:</p>
            <Field
              v-slot="{ field }"
              v-model="model.credentials.password"
              name="model.credentials.password"
            >
              <basic-input
                name="model.credentials.password"
                type="password"
                v-bind="field"
                placeholder="Create a password"
                addon-left-icon="fa-icon"
                fa-icon="key"
                fa-library="fas"
              ></basic-input>
            </Field>
            <basic-input
              name="confirmation"
              type="password"
              placeholder="Re-enter the password"
              addon-left-icon="fa-icon"
              fa-icon="redo"
              fa-library="fas"
            >
            </basic-input>
            <basic-checkbox v-model="model.requirePasswordChange">
              User must change password on first login
            </basic-checkbox>
          </div>
        </div>
        <div
          v-if="model.activationMethod === 'link'"
          class="row justify-content-center"
        >
          <div class="col-md-8 mt-5">
            <p>
              The user will receive an activation link on the following email
              address: <a :href="`mailto:${email}`">{{ email }}</a
              >. The activation link is valid for 24 hours.
            </p>
          </div>
        </div>
      </div>
    </div>
  </VeeForm>
</template>
<script>
// Components
import { ElRadioGroup, ElRadioButton } from "element-plus";

// Vee-validate
import { Form as VeeForm, Field } from "vee-validate";
import { object, string, ref } from "yup";

export default {
  name: "ThirdStep",
  components: {
    [ElRadioGroup.name]: ElRadioGroup,
    [ElRadioButton.name]: ElRadioButton,
    VeeForm,
    Field
  },
  props: {
    email: {
      type: String,
      default: ""
    }
  },
  emits: ["on-validated"],
  setup(values) {
    const schema = object().shape({
      model: object({
        credentials: object({
          password: string().matches(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
            "The password must be at least 8 characters and it must contain one of each: uppercase letter, lowercase letter, number & special character."
          )
        })
      }),
      confirmation: string().oneOf(
        [ref("model.credentials.password"), null],
        "Passwords must match"
      )
    });

    return {
      schema,
      values
    };
  },
  data() {
    return {
      confirmation: "",
      model: {
        credentials: {
          password: ""
        },
        activationMethod: "",
        requirePasswordChange: true
      }
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        if (!res.valid) {
          return !res;
        }
        this.$emit("on-validated", res, this.model);
        return res;
      });
    }
  }
};
</script>
<style></style>
