<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card-component
          class="card-table"
          card-body-classes="table-full-width"
          no-footer-line
        >
          <template #header>
            <h4 class="card-title">Access Requests</h4>
          </template>
          <div>
            <div class="table-searchbar d-flex flex-wrap">
              <div class="col-12">
                <label for="pagination">Show</label>
                <el-select
                  v-model="pagination.perPage"
                  class="select-white pagination"
                  name="pagination"
                >
                  <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
                <label> entries </label>

                <div class="col-3 float-end">
                  <Field
                    v-slot="{ field }"
                    v-model="requestedBy"
                    name="requestedBy"
                  >
                    <basic-input
                      v-bind="field"
                      name="requestedBy"
                      type="text"
                      placeholder="Requester ID..."
                      addon-left-icon="fa-icon fa-fw"
                      fa-icon="search"
                      fa-library="fas"
                    />
                  </Field>
                </div>
                <div class="col-3 float-end">
                  <Field
                    v-slot="{ field }"
                    v-model="requestedFor"
                    name="requestedFor"
                  >
                    <basic-input
                      v-bind="field"
                      name="requestedFor"
                      type="text"
                      placeholder="Subject ID..."
                      addon-left-icon="fa-icon fa-fw"
                      fa-icon="search"
                      fa-library="fas"
                    />
                  </Field>
                </div>
              </div>
            </div>
            <el-table
              v-if="!isLoading"
              stripe
              style="width: 100%"
              :data="tableData"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :width="column.width"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="135" fixed="right" label="Actions">
                <template #default="props">
                  <div class="table-actions">
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="View"
                      placement="top-start"
                    >
                      <basic-button
                        class="view"
                        type="info"
                        size="sm"
                        round
                        icon
                        @click="handleView(props.$index, props.row)"
                      >
                        <font-awesome-icon
                          icon="eye"
                          fixed-width
                        ></font-awesome-icon>
                      </basic-button>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="Edit"
                      placement="top-start"
                    >
                      <basic-button
                        class="edit"
                        type="warning"
                        size="sm"
                        round
                        icon
                        @click="handleEdit(props.$index, props.row)"
                      >
                        <font-awesome-icon
                          icon="wrench"
                          fixed-width
                        ></font-awesome-icon>
                      </basic-button>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="Delete"
                      placement="top-start"
                    >
                      <basic-button
                        class="remove"
                        type="danger"
                        size="sm"
                        round
                        icon
                        @click="handleDelete(props.$index, props.row)"
                      >
                        <font-awesome-icon
                          icon="trash-alt"
                          fixed-width
                        ></font-awesome-icon>
                      </basic-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <loading-animation
              v-else
              color="primary"
              icon-size="6x"
              fa-library="fas"
              fa-icon="spinner"
            >
            </loading-animation>
          </div>
          <template #footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Showing {{ pagination.perPage }} entries
                </p>
              </div>
              <table-pagination
                v-model="pagination.currentPage"
                class="pagination-no-border"
                :per-page="pagination.perPage"
                :total="pagination.total"
              >
              </table-pagination>
            </div>
          </template>
        </card-component>
      </div>
    </div>
  </div>
</template>
<script>
// VENDORS
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElTooltip
} from "element-plus";
import { Field } from "vee-validate";
import Swal from "sweetalert2";

// DATA
import identityNowApi from "@/api/identityNowApi.js";

// VUE INTERNAL
import { ref } from "vue";

export default {
  name: "AccessRequestList",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    ElTooltip,
    Field
  },
  setup() {
    const isLoading = ref(false);
    const error = ref(null);
    const tableData = ref(null);
    const pagination = ref({
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100, 250],
      total: 500
    });
    const tableColumns = ref([
      {
        prop: "created",
        label: "Created",
        width: 200
      },
      {
        prop: "type",
        label: "Type",
        width: 180
      },
      {
        prop: "state",
        label: "State",
        width: 180
      },
      {
        prop: "description",
        label: "Description",
        width: 600
      }
    ]);
    const requestedBy = ref("");
    const requestedFor = ref("");

    const getAccessRequests = async () => {
      isLoading.value = true;
      try {
        await identityNowApi
          .listAccessRequests(pagination.value.perPage, 0, false)
          .then((response) => (tableData.value = response.data));
        isLoading.value = false;
      } catch (e) {
        error.value = e;
      }
    };

    getAccessRequests();

    return {
      isLoading,
      error,
      tableData,
      getAccessRequests,
      pagination,
      tableColumns,
      requestedBy,
      requestedFor
    };
  },
  watch: {
    "pagination.perPage": function () {
      this.isLoading = true;
      identityNowApi
        .listAccessRequests(this.pagination.perPage, 0, false)
        .then((response) => (this.tableData = response.data));
      this.isLoading = false;
    },
    requestedBy() {
      this.isLoading = true;
      identityNowApi
        .listAccessRequests(this.pagination.perPage, 0, false, this.requestedBy)
        .then((response) => (this.tableData = response.data));
      this.isLoading = false;
    },
    requestedFor() {
      this.isLoading = true;
      identityNowApi
        .listAccessRequests(
          this.pagination.perPage,
          0,
          false,
          null,
          this.requestedFor
        )
        .then((response) => (this.tableData = response.data));
      this.isLoading = false;
    }
  },
  methods: {
    handleCreate() {
      this.$localStorage.set("mode", "create");
      this.$localStorage.set("workItemId", "");

      this.$router.push({
        name: "Manage Task"
      });
    },
    handleEdit(index, row) {
      this.$localStorage.set("mode", "edit");
      this.$localStorage.set("workItemId", `${row.id}`);
      this.$localStorage.set("workItemName", `${row.name}`);

      this.$router.push({
        name: "Manage Task"
      });
    },
    handleView(index, row) {
      this.$localStorage.set("mode", "read");
      this.$localStorage.set("workItemId", `${row.id}`);
      this.$localStorage.set("workItemName", `${row.name}`);

      this.$router.push({
        name: "View Task"
      });
    },
    handleDelete(index, row) {
      Swal.fire({
        title: "Are you sure?",
        text: `You're about to delete ${row.name}. You won't be able to revert this!`,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-default btn-fill"
        },
        confirmButtonText: "Yes, delete this group!",
        buttonsStyling: false
      }).then((result) => {
        // If confirmed, delete the group in Okta and remove from table
        if (result.value) {
          this.deleteWorkItem(`${row.id}`);
        }
      });
    },
    async deleteWorkItem(id) {
      try {
        // Delete the group in Okta
        await identityNowApi.deleteWorkItem(id);
        // Delete the group from the table
        let indexToDelete = this.tableData.findIndex(
          (tableRow) => tableRow.id === id
        );
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1);
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "The task could not be deleted. Please contact your administrator. Message: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger"
          },
          buttonsStyling: false
        });
      }
    },
    async handleExport() {
      this.isLoading = true;
      await identityNowApi
        .exportWorkItems(this.pagination.perPage)
        .then((response) => (this.tableData = this.composeAPNames(response)));

      this.isLoading = false;
    }
  }
};
</script>
<style></style>
