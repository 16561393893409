import axios from "axios";

const oktaURL = "https://okta.tymezone.nl";

// CREATE AXIOS CLIENT
const client = axios.create({
  baseURL: oktaURL,
  json: true
});

export default {
  // CREATE THE REQUEST BY COMBINING THE OPERATION AND THE DATA
  async execute(method, resource, data) {
    // inject the accessToken from localStorage for each request
    let oktaTokenStorage = JSON.parse(
      localStorage.getItem("okta-token-storage")
    );
    let accessToken = oktaTokenStorage.accessToken.accessToken;

    return client({
      method,
      url: resource,
      data,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then((req) => {
      return req.data;
    });
  },

  /*
   * USER OPERATIONS
   */

  /* USER SEARCH OPERATIONS */
  getUsers(limit) {
    return this.execute("get", `/api/v1/users?limit=${limit}`);
  },

  findUsers(query, limit) {
    return this.execute("get", `/api/v1/users?q=${query}&limit=${limit}`);
  },

  filterUsers(filter, limit) {
    // https://developer.okta.com/docs/reference/api/users/#list-users-with-a-filter
    return this.execute("get", `/api/v1/users?filter=${filter}&limit=${limit}`);
  },

  /* USER MANAGEMENT OPERATIONS */
  getUser(userId) {
    return this.execute("get", `/api/v1/users/${userId}`);
  },

  getCurrentUser() {
    return this.execute("get", "/api/v1/users/me");
  },

  createInactiveUser(data) {
    return this.execute("post", "/api/v1/users?activate=false", data);
  },

  createActiveUser(data) {
    return this.execute("post", "/api/v1/users?activate=true", data);
  },

  createActiveUserWithChangePassword(data) {
    return this.execute(
      "post",
      `/api/v1/users?activate=true&nextLogin=changePassword`,
      data
    );
  },

  updateUser(userId, data) {
    return this.execute("post", `/api/v1/users/${userId}`, data);
  },

  updateCurrentUser(data) {
    return this.execute("post", "/api/v1/users/me", data);
  },

  deleteUser(userId) {
    // Deletes a user permanently. This operation can only be performed on users that have a DEPROVISIONED status.
    // This action cannot be recovered!
    // This operation on a user that hasn't been deactivated causes that user to be deactivated.
    // A second delete operation is required to delete the user.
    return this.execute("delete", `/api/v1/users/${userId}?sendEmail=true`);
  },

  /*
   * CREDENTIAL OPERATIONS
   */

  changePassword(userId, data) {
    // Changes a user's password by validating the user's current password
    return this.execute(
      "post",
      `/api/v1/users/${userId}/credentials/change_password`,
      data
    );
  },

  resetPassword(userId, sendEmail) {
    // Resets the user's password and returns a OTT to the user by email or to the client (Vue app)
    return this.execute(
      "post",
      `/api/v1/users/${userId}/credentials/forgot_password?sendEmail=${sendEmail}`
    );
  },

  resetMultifactor(userId) {
    return this.execute(
      "post",
      `/api/v1/users/${userId}/lifecycle/reset_factors`
    );
  },

  /*
   * LIFECYCLE OPERATIONS
   */

  suspendUser(userId) {
    return this.execute("post", `/api/v1/users/${userId}/lifecycle/suspend`);
  },

  unsuspendUser(userId) {
    return this.execute("post", `/api/v1/users/${userId}/lifecycle/unsuspend`);
  },

  activateUser(userId) {
    // This operation can only be performed on users with a STAGED or DEPROVISIONED status.
    // Activation of a user is an asynchronous operation.
    return this.execute(
      "post",
      `/api/v1/users/${userId}/lifecycle/activate?sendEmail=true`
    );
  },

  deactivateUser(userId) {
    return this.execute(
      "post",
      `/api/v1/users/${userId}/lifecycle/deactivate?sendEmail=true`
    );
  },

  reactivateUser(userId) {
    return this.execute(
      "post",
      `/api/v1/users/${userId}/lifecycle/reactivate?sendEmail=true`
    );
  },

  unlockUser(userId) {
    return this.execute("post", `/api/v1/users/${userId}/lifecycle/unlock`);
  },

  /*
   * GROUP OPERATIONS
   */

  getGroups(limit) {
    // Gets a single group by Id
    return this.execute("get", `/api/v1/groups?limit=${limit}`);
  },

  getGroup(groupId) {
    return this.execute("get", `/api/v1/groups/${groupId}`);
  },

  findGroup(query, limit) {
    return this.execute("get", `/api/v1/groups?q=${query}&limit=${limit}`);
  },

  createGroup(data) {
    return this.execute("post", "/api/v1/groups", data);
  },

  updateGroup(groupId, data) {
    return this.execute("put", `/api/v1/groups/${groupId}`, data);
  },

  deleteGroup(groupId) {
    return this.execute("delete", `/api/v1/groups/${groupId}`);
  },

  /*
   * GROUP MEMBER OPERATIONS
   */

  listGroupMembers(groupId, limit) {
    return this.execute(
      "get",
      `/api/v1/groups/${groupId}/users?limit=${limit}`
    );
  },

  addUserToGroup(groupId, userId) {
    return this.execute("put", `/api/v1/groups/${groupId}/users/${userId}`);
  },

  removeUserFromGroup(groupId, userId) {
    return this.execute("delete", `/api/v1/groups/${groupId}/users/${userId}`);
  }
};
