<template>
  <div class="content-with-header">
    <div v-if="mode !== 'create'" class="panel-header panel-header-md">
      <div class="header text-center">
        <h2 class="title">{{ groupName }}</h2>
      </div>
    </div>
    <div v-else class="panel-header panel-header-md"></div>
    <div id="group-edit-page" class="content">
      <div class="row">
        <!-- CREATE MODE -->
        <div v-if="mode === 'create'" class="col-12">
          <card-component id="group-create-card">
            <template #header>
              <h4 class="card-title">Create Group</h4>
            </template>
            <group-edit-details
              :mode="mode"
              @group-operation-by-child="onCreateOrUpdate"
            >
            </group-edit-details>
          </card-component>
        </div>
        <!-- CREATE MODE END -->

        <!-- EDIT MODE -->
        <div v-if="mode === 'edit'" class="col-12">
          <card-component>
            <template #header>
              <h4 class="card-title">
                Group Management -
                <small class="description">Use the tabs to navigate</small>
              </h4>
            </template>

            <tabs-container v-model:value="tabs.horizontal" type="primary">
              <!-- TAB: GROUP DETAILS -->
              <tab-pane
                id="edit-details"
                label="Edit Details"
                icon="edit"
                fa-icon="edit"
                fa-library="far"
                iconclass="tab-icon-horizontal"
              >
                <group-edit-details
                  :mode="mode"
                  :group-id="groupId"
                  @group-operation-by-child="onCreateOrUpdate"
                />
              </tab-pane>

              <!-- TAB: MEMBERSHIP -->
              <tab-pane
                v-if="mode === 'edit'"
                id="membership"
                label="Membership"
                icon="users-cog"
                fa-icon="users-cog"
                fa-library="fas"
                iconclass="tab-icon-horizontal"
              >
                <group-edit-membership :mode="mode" :group-id="groupId" />
              </tab-pane>
            </tabs-container>
          </card-component>
        </div>
        <!-- EDIT MODE END-->
      </div>
    </div>
  </div>
</template>
<script>
// Tabs
import TabsContainer from "@/components/Tabs/TabsContainer";
import TabPane from "@/components/Tabs/TabPane";

// Subcomponents
import GroupEditDetails from "./components/GroupEditDetails";
import GroupEditMembership from "./components/GroupEditMembership";

export default {
  name: "GroupEditView",
  components: {
    GroupEditDetails,
    GroupEditMembership,
    TabPane,
    TabsContainer
  },
  data() {
    return {
      activeName: "first",
      tabs: {
        horizontal: "Edit Details"
      },
      mode: this.$localStorage.get("mode"),
      groupId: this.$localStorage.get("groupId"),
      groupName: this.$localStorage.get("groupName")
    };
  },
  methods: {
    onCreateOrUpdate(mode, groupId, groupName) {
      this.mode = mode;
      this.groupId = groupId;
      this.groupName = groupName;
    }
  }
};
</script>
<style></style>
