<template>
  <div class="content-with-header">
    <div v-if="mode !== 'create'" class="panel-header panel-header-md">
      <div class="header text-center">
        <h2 class="title">{{ roleName }}</h2>
      </div>
    </div>
    <div v-else class="panel-header panel-header-md"></div>
    <div id="role-edit-page" class="content">
      <div class="row">
        <!-- CREATE MODE -->
        <div v-if="mode === 'create'" class="col-12">
          <card-component id="role-create-card">
            <template #header>
              <h4 class="card-title">Create Role</h4>
            </template>
            <role-edit-details
              :mode="mode"
              @role-operation-by-child="onCreateOrUpdate"
            >
            </role-edit-details>
          </card-component>
        </div>
        <!-- CREATE MODE END -->

        <!-- EDIT MODE -->
        <div v-if="mode === 'edit'" class="col-12">
          <card-component>
            <template #header>
              <h4 class="card-title">
                Role Management -
                <small class="description">Use the tabs to navigate</small>
              </h4>
            </template>

            <tabs-container v-model:value="tabs.horizontal" type="primary">
              <!-- TAB: ROLE DETAILS -->
              <tab-pane
                id="edit-details"
                label="Edit Details"
                icon="edit"
                fa-icon="edit"
                fa-library="far"
                iconclass="tab-icon-horizontal"
              >
                <role-edit-details
                  :mode="mode"
                  :role-id="roleId"
                  @role-operation-by-child="onCreateOrUpdate"
                />
              </tab-pane>
            </tabs-container>
          </card-component>
        </div>
        <!-- EDIT MODE END-->
      </div>
    </div>
  </div>
</template>
<script>
// Tabs
import TabsContainer from "@/components/Tabs/TabsContainer";
import TabPane from "@/components/Tabs/TabPane";

// Subcomponents
import RoleEditDetails from "./components/RoleEditDetails";

export default {
  name: "RoleEditView",
  components: {
    RoleEditDetails,
    TabPane,
    TabsContainer
  },
  data() {
    return {
      activeName: "first",
      tabs: {
        horizontal: "Edit Details"
      },
      mode: this.$localStorage.get("mode"),
      roleId: this.$localStorage.get("roleId"),
      roleName: this.$localStorage.get("roleName")
    };
  },
  methods: {
    onCreateOrUpdate(mode, roleId, roleName) {
      this.mode = mode;
      this.roleId = roleId;
      this.roleName = roleName;
    }
  }
};
</script>
<style></style>
