<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card-component
          class="card-table"
          card-body-classes="table-full-width"
          no-footer-line
        >
          <template #header>
            <h4 class="card-title">Groups</h4>
          </template>
          <div>
            <div class="col-12 mb-1">
              <basic-button type="primary mt-0" @click="handleCreate()">
                <font-awesome-icon icon="plus" fixed-width></font-awesome-icon>
                Create Group
              </basic-button>
            </div>
            <div class="table-searchbar d-flex flex-wrap">
              <div class="col-12">
                <label for="pagination">Show</label>
                <el-select
                  v-model="pagination.perPage"
                  class="select-white pagination"
                  name="pagination"
                >
                  <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
                <label> entries </label>

                <div class="float-end">
                  <Field
                    v-slot="{ field }"
                    v-model="searchQuery"
                    name="searchQuery"
                  >
                    <basic-input
                      v-bind="field"
                      name="searchQuery"
                      type="text"
                      placeholder="Search..."
                      addon-left-icon="fa-icon fa-fw"
                      fa-icon="search"
                      fa-library="fas"
                    />
                  </Field>
                </div>
              </div>
            </div>
            <el-table v-if="!isLoading" stripe :data="tableData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :width="column.width"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="135" fixed="right" label="Actions">
                <template #default="props">
                  <div class="table-actions">
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="View"
                      placement="top-start"
                    >
                      <basic-button
                        class="view"
                        type="info"
                        size="sm"
                        round
                        icon
                        @click="handleView(props.$index, props.row)"
                      >
                        <font-awesome-icon
                          icon="eye"
                          fixed-width
                        ></font-awesome-icon>
                      </basic-button>
                    </el-tooltip>
                    <el-tooltip
                      v-if="props.row.type == 'OKTA_GROUP'"
                      class="item"
                      effect="light"
                      content="Edit"
                      placement="top-start"
                    >
                      <basic-button
                        class="edit"
                        type="warning"
                        size="sm"
                        round
                        icon
                        @click="handleEdit(props.$index, props.row)"
                      >
                        <font-awesome-icon
                          icon="wrench"
                          fixed-width
                        ></font-awesome-icon>
                      </basic-button>
                    </el-tooltip>
                    <el-tooltip
                      v-if="props.row.type == 'OKTA_GROUP'"
                      class="item"
                      effect="light"
                      content="Delete"
                      placement="top-start"
                    >
                      <basic-button
                        class="remove"
                        type="danger"
                        size="sm"
                        round
                        icon
                        @click="handleDelete(props.$index, props.row)"
                      >
                        <font-awesome-icon
                          icon="trash-alt"
                          fixed-width
                        ></font-awesome-icon>
                      </basic-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <loading-animation
              v-else
              color="primary"
              icon-size="6x"
              fa-library="fas"
              fa-icon="spinner"
            >
            </loading-animation>
          </div>
          <template #footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Showing {{ pagination.perPage }} entries
                </p>
              </div>
            </div>
          </template>
        </card-component>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElTooltip
} from "element-plus";
import { Field } from "vee-validate";

import oktaApi from "@/api/oktaClientApi.js";
import Swal from "sweetalert2";

export default {
  name: "GroupList",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    ElTooltip,
    Field
  },
  data() {
    return {
      isLoading: true,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100, 200]
      },
      searchQuery: "",
      tableColumns: [
        {
          prop: "profile.name",
          label: "Name",
          width: 250
        },
        {
          prop: "type",
          label: "Type",
          width: 150
        },
        {
          prop: "profile.description",
          label: "Description",
          width: 700
        }
      ],
      tableData: []
    };
  },
  watch: {
    "pagination.perPage": function () {
      oktaApi
        .getGroups(this.pagination.perPage)
        .then((response) => (this.tableData = response));
    },
    searchQuery: function () {
      oktaApi.findGroup(this.searchQuery, 10).then((response) => {
        this.tableData = response;
      });
    }
  },
  async created() {
    // Get users and load tableData with default pagination
    this.isLoading = true;
    await oktaApi
      .getGroups(this.pagination.perPage)
      .then((response) => (this.tableData = response));
    this.isLoading = false;
  },
  methods: {
    handleCreate() {
      this.$localStorage.set("mode", "create");
      this.$localStorage.set("groupId", "");

      this.$router.push({
        name: "Manage Group"
      });
    },
    handleEdit(index, row) {
      this.$localStorage.set("mode", "edit");
      this.$localStorage.set("groupId", `${row.id}`);
      this.$localStorage.set("groupName", `${row.profile.name}`);

      this.$router.push({
        name: "Manage Group"
      });
    },
    handleView(index, row) {
      this.$localStorage.set("mode", "read");
      this.$localStorage.set("groupId", `${row.id}`);
      this.$localStorage.set("groupName", `${row.profile.name}`);

      this.$router.push({
        name: "View Group"
      });
    },
    handleDelete(index, row) {
      Swal.fire({
        title: "Are you sure?",
        text: `You're about to delete ${row.profile.name}. You won't be able to revert this!`,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-default btn-fill"
        },
        confirmButtonText: "Yes, delete this group!",
        buttonsStyling: false
      }).then((result) => {
        // If confirmed, delete the group in Okta and remove from table
        if (result.value) {
          this.deleteOktaGroup(`${row.id}`);
        }
      });
    },
    async deleteOktaGroup(id) {
      try {
        // Delete the group in Okta
        await oktaApi.deleteGroup(id);
        // Delete the group from the table
        let indexToDelete = this.tableData.findIndex(
          (tableRow) => tableRow.id === id
        );
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1);
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "The group could not be deleted. Please contact your administrator. Message: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger"
          },
          buttonsStyling: false
        });
      }
    }
  }
};
</script>
<style></style>
