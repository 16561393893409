<template>
  <div
    class="choice"
    :class="{ active: modelValue }"
    data-bs-toggle="wizard-checkbox"
    @click="updateValue"
  >
    <input
      type="checkbox"
      :name="name"
      :disabled="disabled"
      :checked="modelValue"
    />
    <div class="icon">
      <slot name="icon">
        <font-awesome-icon
          fixed-width
          :icon="[`${faLibrary}`, `${faIcon}`]"
          :class="icon"
          size="2x"
        />
      </slot>
    </div>
    <slot name="title">
      <h6>{{ title }}</h6>
    </slot>
  </div>
</template>
<script>
export default {
  name: "IconCheckbox",
  model: {
    prop: "modelValue"
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    faLibrary: {
      type: String,
      default: ""
    },
    faIcon: {
      type: String,
      default: ""
    },
    disabled: Boolean
  },
  emits: ["update:modelValue"],
  methods: {
    updateValue() {
      this.$emit("update:modelValue", !this.modelValue);
    }
  }
};
</script>
<style></style>
