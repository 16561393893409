<template>
  <div class="row">
    <div class="col-md-4">
      <card-component plain>
        <template #header>
          <h5>Manage members</h5>
        </template>
        <div>
          <p class="card-text">
            Use the dropdown to search, add and remove users. Your changes are
            automatically saved and reflected in the table.
          </p>
          <div class="multiselect-container">
            <VueMultiselect
              id="ajax"
              v-model="selectedUsers"
              label="displayName"
              :custom-label="customLabel"
              track-by="id"
              placeholder="Type to search"
              open-direction="bottom"
              :options="users"
              :multiple="true"
              :searchable="true"
              :loading="isLoading"
              :internal-search="false"
              :clear-on-select="true"
              :close-on-select="true"
              :options-limit="200"
              :limit="5"
              :limit-text="limitText"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              @search-change="asyncFind"
              @remove="removeUser"
              @select="addUser"
            >
              <template #tag="{ option, remove }">
                <span class="custom__tag">
                  <span>{{ option.profile.displayName }}</span>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Remove user from group"
                    placement="top-start"
                  >
                    <span class="custom__remove" @click="remove(option)"
                      >❌</span
                    >
                  </el-tooltip>
                </span>
              </template>
              <template #clear="props">
                <div
                  v-if="selectedUsers.length"
                  class="multiselect__clear"
                  @mousedown.prevent.stop="clearAll(props.search)"
                ></div>
              </template>
              <template #noResult>
                <span>
                  Oops! No users found. Consider changing the search query.
                </span>
              </template>
            </VueMultiselect>
          </div>
        </div>
      </card-component>
    </div>
    <div class="col-md-8">
      <card-component
        id="membership-card"
        plain
        card-body-classes="table-full-width"
        no-footer-line
      >
        <template #header>
          <h5>Members</h5>
        </template>
        <div>
          <el-table stripe style="width: 100%" :data="selectedUsers">
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
          </el-table>
        </div>
        <template #footer>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <p class="card-category">Showing 200 entries max</p>
          </div>
        </template>
      </card-component>
    </div>
  </div>
</template>
<script>
import oktaApi from "@/api/oktaClientApi.js";
import Swal from "sweetalert2";
import VueMultiselect from "vue-multiselect";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElTooltip
} from "element-plus";

export default {
  name: "GroupEditMembership",
  components: {
    VueMultiselect,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    ElTooltip
  },
  props: {
    mode: {
      type: String,
      default: "edit"
    },
    groupId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      groupName: "",
      selectedUsers: [],
      selectedUsersOriginal: [],
      users: [],
      isLoading: false,
      searchLimit: 10, // This is the Okta default
      tableColumns: [
        {
          prop: "profile.firstName",
          label: "First Name",
          minWidth: 150
        },
        {
          prop: "profile.lastName",
          label: "Last Name",
          minWidth: 150
        },
        {
          prop: "profile.email",
          label: "Email",
          minWidth: 250
        }
      ]
    };
  },
  mounted() {
    // Get current group from the route
    oktaApi
      .getGroup(this.groupId)
      .then((response) => (this.groupName = response.profile.name));
    // Get current group members and load selectedUsers
    oktaApi
      .listGroupMembers(this.groupId, 200)
      .then(
        (response) => (
          (this.selectedUsers = response),
          (this.selectedUsersOriginal = response)
        )
      );
    // Load default users for search
    oktaApi.findUsers("", 200).then((response) => {
      this.users = response;
      this.isLoading = false;
    });
  },
  methods: {
    // Multiselect methods
    customLabel({ profile }) {
      return `${profile.displayName}` + ` (${profile.login})`;
    },
    limitText(count) {
      return `and ${count} other users`;
    },
    asyncFind(query) {
      this.isLoading = true;
      oktaApi.findUsers(query, this.searchLimit).then((response) => {
        this.users = response;
        this.isLoading = false;
      });
    },
    clearAll() {
      this.selectedUsers = [];
    },
    addUser(selectedOption) {
      Swal.fire({
        title: "Are you sure?",
        text: `This will add ${selectedOption.profile.displayName} to the group!`,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-default btn-fill"
        },
        confirmButtonText: "Yes, add the user!",
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          oktaApi.addUserToGroup(this.groupId, selectedOption.id);
        } else {
          const index = this.selectedUsers.indexOf(selectedOption);
          if (index > -1) {
            this.selectedUsers.splice(index, 1);
          }
        }
      });
    },
    removeUser(removedOption) {
      Swal.fire({
        title: "Are you sure?",
        text: `This will remove ${removedOption.profile.displayName} from the group!`,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-default btn-fill"
        },
        confirmButtonText: "Yes, remove the user!",
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          oktaApi.removeUserFromGroup(this.groupId, removedOption.id);
        } else {
          this.selectedUsers.push(removedOption);
        }
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
