<template>
  <footer
    :class="footerClasses"
    class="footer"
    :data-background-color="backgroundColor"
  >
    <div class="container-fluid">
      <div class="copyright pe-0">
        Copyright &copy; {{ year }} | Created by Thijmen Duijve |
        <a href="https://www.securityme.nl" target="_blank" rel="noopener"
          >securityme</a
        >
        |
        <router-link to="/terms">Terms and Conditions</router-link>
        |
        <router-link to="/privacy">Privacy Policy</router-link>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "MainFooter",
  props: {
    backgroundColor: {
      type: String,
      default: "black"
    },
    footerClasses: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
