<template>
  <VeeForm ref="form" :validation-schema="schema" @submit="validate">
    <h5 class="info-text">Let's start with the basics.</h5>
    <div class="row justify-content-center">
      <div class="col-md-10">
        <h6>
          <font-awesome-icon
            :icon="['far', 'id-badge']"
            fixed-width
          ></font-awesome-icon
          >Details (required)
        </h6>
        <div class="row">
          <div class="col-sm-6">
            <Field
              v-slot="{ field }"
              v-model="model.firstName"
              name="firstName"
            >
              <basic-input
                name="firstName"
                type="text"
                v-bind="field"
                label="First Name *"
                placeholder="First Name"
                addon-left-icon="fa-icon fa-fw"
                fa-icon="signature"
                fa-library="fas"
              >
              </basic-input>
            </Field>
          </div>

          <div class="col-sm-6">
            <Field v-slot="{ field }" v-model="model.lastName" name="lastName">
              <basic-input
                name="lastName"
                type="text"
                v-bind="field"
                label="Last Name *"
                placeholder="Last Name"
                addon-left-icon="fa-icon fa-fw"
                fa-icon="address-card"
                fa-library="far"
              >
              </basic-input>
            </Field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <Field v-slot="{ field }" v-model="model.email" name="email">
              <basic-input
                name="email"
                type="email"
                v-bind="field"
                label="Email *"
                placeholder="Email"
                addon-left-icon="fa-icon fa-fw"
                fa-icon="envelope"
                fa-library="far"
              >
              </basic-input>
            </Field>
          </div>
        </div>
      </div>
      <div class="col-md-10 mt-3">
        <h6>
          <font-awesome-icon
            :icon="['far', 'address-card']"
            fixed-width
          ></font-awesome-icon
          >Address (optional)
        </h6>

        <div class="row">
          <div class="col-sm-6">
            <Field
              v-slot="{ field }"
              v-model="model.streetAddress"
              name="streetAddress"
            >
              <basic-input
                name="streetAddress"
                type="text"
                v-bind="field"
                label="Street Address"
                placeholder="Street Name + № "
              >
              </basic-input>
            </Field>
          </div>
          <div class="col-sm-6">
            <Field v-slot="{ field }" v-model="model.zipCode" name="zipCode">
              <basic-input
                name="zipCode"
                type="text"
                v-bind="field"
                label="Postal Code"
                placeholder="XXXX XX"
              >
              </basic-input>
            </Field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <Field v-slot="{ field }" v-model="model.city" name="city">
              <basic-input
                name="city"
                type="text"
                v-bind="field"
                label="City"
                placeholder="Hometown"
              >
              </basic-input>
            </Field>
          </div>
          <div class="col-sm-6">
            <label for="countryCode">Country</label>
            <el-select
              v-model="model.countryCode"
              class="select-white"
              name="countryCode"
              filterable
            >
              <el-option
                v-for="option in options.countries"
                :key="option.name"
                class="select-primary"
                :label="option.name"
                :value="option.code"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
  </VeeForm>
</template>
<script>
// Data
import countries from "@/data/countries.json";

// Components
import { ElSelect, ElOption } from "element-plus";

// Vee-validate
import { Form as VeeForm, Field } from "vee-validate";
import { object, string } from "yup";

export default {
  name: "FirstStep",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    VeeForm,
    Field
  },
  emits: ["on-validated"],
  setup(values) {
    const schema = object().shape({
      firstName: string().required().label("The First Name"),
      email: string().email().required().label("The Email"),
      lastName: string().required().label("The Last Name"),
      streetAddress: string().label("Street address"),
      zipCode: string().matches(/^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i, {
        message: "Must be a valid postal code e.g. 1234 AB",
        excludeEmptyString: true
      }),
      city: ""
    });

    return {
      schema,
      values
    };
  },
  data() {
    return {
      model: {
        firstName: "",
        lastName: "",
        displayName: "",
        email: "",
        login: "",
        streetAddress: "",
        zipCode: "",
        city: "",
        countryCode: ""
      },
      options: {
        countries: countries
      }
    };
  },
  watch: {
    // The login name of the user is derived from the email attribute
    "model.email": function () {
      this.model.login = this.model.email;
    },
    // Calculate displayName based on firstName + lastName
    "model.firstName": function () {
      this.model.displayName = this.model.firstName + " " + this.model.lastName;
    },
    "model.lastName": function () {
      this.model.displayName = this.model.firstName + " " + this.model.lastName;
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    }
  }
};
</script>
<style></style>
