const { OKTA_CLIENT_ID, OKTA_ISSUER, OKTA_TESTING_DISABLEHTTPSCHECK } =
  process.env;

const REDIRECT_URI = process.env.BASE_URL + "auth/callback";
const OKTA_AUTH_ENABLED = true;

export default {
  oktaAuthEnabled: OKTA_AUTH_ENABLED, // Used to toggle Okta auth on/off for the app
  // enabledMfaProviders: ["OKTA", "GOOGLE"], // Used to determine which providers to show in MFA-form
  corsWhitelist: ["http://localhost:8080"], // Used to enable CORS between the client and the backend
  oidc: {
    clientId: OKTA_CLIENT_ID,
    issuer: OKTA_ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: [
      "openid",
      "profile",
      "email",
      "okta.users.manage.self",
      "okta.users.read.self",
      "okta.users.manage",
      "okta.users.read",
      "okta.groups.manage",
      "okta.groups.read"
    ],
    pkce: true,
    testing: {
      disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK
    }
  }
};
