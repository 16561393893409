<template>
  <div class="container">
    <div v-if="!isLoading" class="row justify-content-center">
      <!-- Show Okta widget when the link is valid or a state token exists -->
      <div
        v-if="validLink || validStateToken"
        class="col-xxl-4 col-xl-5 col-lg-6 col-md-7"
      >
        <!-- Okta widget -->
        <div id="okta-signin-container"></div>
      </div>
      <!-- Direct user to registration page when neither is valid  -->
      <div
        v-if="!validLink && !validStateToken"
        class="col-xl-4 col-lg-5 col-md-6"
      >
        <div class="card mb-0">
          <div class="card-header pt-5 border-bottom-0 text-center">
            <h2>Activation link expired</h2>
          </div>

          <div class="row justify-content-center">
            <font-awesome-icon
              class="pt-3"
              size="6x"
              :icon="['fas', 'hourglass-end']"
            />
          </div>
          <div class="card-body pt-3 pb-0 px-5">
            <div class="text-center text-muted mb-4">
              <small>
                It appears your activation link is no longer valid. Use the
                button below to request a new account.
              </small>
            </div>
            <div class="mb-4 text-center">
              <router-link
                to="/signin/register"
                class="btn btn-round btn-primary mt-2"
              >
                Back to registration page
              </router-link>
            </div>
          </div>
          <div class="card-footer bg-transparent border-top-0 px-lg-5"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Okta stuff
import OktaSignIn from "@okta/okta-signin-widget";
import oktaConfig from "@/auth/oktaConfig";

// Packages
import axios from "axios";

export default {
  name: "ActivateUser",
  props: {
    activationToken: {
      type: String,
      default: "",
      description:
        "The activation token that is parsed from the URL in the activation email."
    }
  },
  data() {
    return {
      stateToken: "",
      validStateToken: false,
      validLink: false,
      isLoading: true
    };
  },
  async mounted() {
    /* We bootstrap the sign in widget with the state token by:
     * - Verifying the activation token and exchange it with a state token
     * - Storing the state token in local storage with the expiry date
     * - If the activation token is no longer valid, we check for the existance of valid state token
     * - If the state token is no longer valid, we tell the user to head back to the registration page
     */

    // We verify the activation token and exchange it with a state token
    // Next, we store the state token in local storage with the expiry date
    this.stateToken = await this.validateLinkAndRetrieveStateToken();

    // If the activation token is no longer valid, we check for the existance of valid state token
    if (!this.validLink) {
      this.validStateToken = this.verifyStateToken();

      // We update the stateToken from localStorage if it is valid
      if (this.validStateToken) {
        this.stateToken = localStorage.getItem("stateToken");
      }
    }

    this.isLoading = false;

    const vue = this;

    // Load the sign in widget
    this.$nextTick(function () {
      const { issuer, clientId, redirectUri, scopes } = oktaConfig.oidc;

      this.widget = new OktaSignIn({
        baseUrl: issuer.split("/oauth2")[0],
        clientId,
        redirectUri,
        stateToken: this.stateToken, // This is used to bootstrap the widget
        authParams: {
          issuer,
          scopes
        },
        logo: "/img/brand/grabowsky-logo.png",
        logoText: "Grabowsky",
        helpSupportNumber: "+31 6 1234 4321",
        brandname: "Grabowsky Toolbox",
        i18n: {
          en: {
            "password.reset.title.generic": "Choose a password",
            "password.reset": "Continue"
          },
          "nl-NL": {
            "password.reset.title.generic": "Kies uw wachtwoord",
            "password.reset": "Doorgaan"
          }
        }
      });

      // Change the behaviour of the "Back to sign" in link to navigate to the sign in widget
      this.widget.on("afterRender", function (context) {
        console.log(context.controller);
        if (context.controller !== "password-reset") {
          return;
        }

        var backLink = document.getElementsByClassName("goto")[0];
        backLink.addEventListener("click", function (e) {
          e.preventDefault();
          e.stopPropagation();
          // Custom link behavior
          vue.$router.push({
            name: "SignIn"
          });
        });
      });

      // Tell the widget where to go after successful authentication
      const originalUri = this.$auth.getOriginalUri();
      if (!originalUri) {
        this.$auth.setOriginalUri("/");
      }

      this.widget
        .showSignInToGetTokens({
          el: "#okta-signin-container"
        })
        .then((tokens) => {
          // Remove stateToken from localStorage after successful sign in
          localStorage.removeItem("stateToken");
          localStorage.removeItem("expiresAt");
          this.$auth.handleLoginRedirect(tokens);
        })
        .catch((err) => {
          throw err;
        });
    });
  },
  unmounted() {
    // Remove the widget from the DOM on path change
    this.widget.remove();
  },
  beforeMount() {
    if (this.authState && this.authState.isAuthenticated) {
      this.$router.push({
        name: "Authenticated"
      });
    }
  },
  methods: {
    verifyStateToken() {
      let stateToken = localStorage.getItem("stateToken");
      console.log("State token is now: " + stateToken);
      if (stateToken) {
        let expiresAt = localStorage.getItem("expiresAt");
        if (!isNaN(Date.parse(expiresAt))) {
          let currentUtcDate = new Date().toISOString();
          console.log("The current utc date is: " + currentUtcDate);
          let expirationDate = new Date(expiresAt).toISOString();
          console.log("The current expiration date is: " + expirationDate);

          return currentUtcDate < expirationDate;
        }
      }
      return false;
    },
    async validateLinkAndRetrieveStateToken() {
      const url = oktaConfig.oidc.issuer + "api/v1/authn";
      let res = {};

      try {
        res = await axios.post(url, {
          token: this.activationToken
        });
        // Store token and expiry date in localStorage and set validLink to true
        localStorage.setItem("stateToken", res.data.stateToken);
        localStorage.setItem("expiresAt", res.data.expiresAt);
        this.validLink = true;
        return res.data.stateToken;
      } catch {
        this.validLink = false;
      }
    }
  }
};
</script>
<style src="@/assets/css/okta-sign-in.min.css"></style>
