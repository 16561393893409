<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-4 col-lg-5 col-md-6">
        <div class="card mb-0">
          <div class="card-header pt-5 border-bottom-0 text-center">
            <h2>You're logged in!</h2>
          </div>

          <div class="row justify-content-center">
            <font-awesome-icon
              class="pt-3 text-primary"
              size="6x"
              :icon="['fas', 'door-open']"
            />
          </div>
          <div class="card-body pt-3 pb-0 px-5">
            <div class="text-center mb-4">
              <small>
                It appears you have an active session. Hit the button to enter
                the toolbox or use the link below to sign out.
              </small>
            </div>
            <div class="mb-4 text-center">
              <router-link to="/toolbox" class="btn btn-round btn-primary mt-2">
                Take me to the toolbox
              </router-link>
            </div>
          </div>
          <div class="card-footer border-top-0 px-lg-5 text-end">
            <div class="row pb-3">
              <div class="col-12">
                <a class="fs-6" href="/" @click.prevent="signOut()">
                  Sign me out
                  <font-awesome-icon class="ms-1" :icon="['fas', 'running']" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AuthSuccess",
  methods: {
    async signOut() {
      await this.$auth.signOut();
    }
  }
};
</script>
<style></style>
