// Create Router
import { createRouter, createWebHistory } from "vue-router";

// Authentication
import oktaConfig from "@/auth/oktaConfig";
import {
  navigationGuard,
  LoginCallback as OktaLoginCallback
} from "@okta/okta-vue"; // Okta navigation guard & default LoginCallback

// Import Layouts
import AuthLayout from "@/views/Layout/AuthLayout.vue";
import ToolboxLayout from "@/views/Layout/ToolboxLayout.vue";

// General Pages
import NotFound from "@/views/NotFoundPage.vue";

// Auth Pages
import SignIn from "@/views/Authentication/SignIn.vue";
import SignUp from "@/views/Authentication/SignUp.vue";
import ActivateUser from "@/views/Authentication/ActivateUser.vue";
import AuthSuccess from "@/views/Authentication/AuthSuccess.vue";
import TestApi from "@/views/Authentication/TestApi.vue";
import PrivacyPolicy from "@/views/Authentication/PrivacyPolicy.vue";
import TermsAndConditions from "@/views/Authentication/TermsAndConditions.vue";

// Page Headers
import ToolboxHeader from "@/views/Layout/Toolbox/ToolboxHeader";
import DefaultHeader from "@/views/Layout/Toolbox/DefaultHeader";

// Toolbox pages
import ToolboxView from "@/views/Toolbox/ToolboxView.vue";
//// Okta
import UserList from "@/views/Toolbox/Okta/User/UserList.vue";
import UserView from "@/views/Toolbox/Okta/User/UserView.vue";
import GroupList from "@/views/Toolbox/Okta/GroupList.vue";
import GroupReadView from "@/views/Toolbox/Okta/Group/GroupReadView.vue";
import GroupEditView from "@/views/Toolbox/Okta/Group/GroupEditView.vue";
//// SailPoint
import RoleList from "@/views/Toolbox/SailPoint/RoleList.vue";
import RoleReadView from "@/views/Toolbox/SailPoint/Role/RoleReadView.vue";
import RoleEditView from "@/views/Toolbox/SailPoint/Role/RoleEditView.vue";
import AccessProfileList from "@/views/Toolbox/SailPoint/AccessProfileList.vue";
import AccessProfileBulkCreation from "@/views/Toolbox/SailPoint/AccessProfile/AccessProfileBulkCreation.vue";
import EntitlementRemovalFromAPs from "@/views/Toolbox/SailPoint/AccessProfile/EntitlementRemovalFromAPs.vue";
import PendingTasksList from "@/views/Toolbox/SailPoint/PendingTaskList.vue";
import CompletedTasksList from "@/views/Toolbox/SailPoint/CompletedTaskList.vue";
import TaskView from "@/views/Toolbox/SailPoint/Task/TaskView.vue";
import AccessRequestList from "@/views/Toolbox/SailPoint/AccessRequestList.vue";

// User Profile
import UserProfile from "@/views/Toolbox/UserProfile/UserProfile.vue";
import Claims from "@/views/Toolbox/UserProfile/UserClaims.vue";
import Avatar from "@/views/Toolbox/UserProfile/UserAvatar.vue";
import Settings from "@/views/Toolbox/UserProfile/UserSettings.vue";
import Password from "@/views/Toolbox/UserProfile/UserPassword.vue";

const routes = [
  {
    path: "/auth/callback",
    component: OktaLoginCallback
  },
  {
    path: "/",
    name: "AuthLayout",
    redirect: "/signin",
    component: AuthLayout,
    children: [
      {
        path: "/signin",
        name: "SignIn",
        components: { default: SignIn }
      },
      {
        path: "/signin/register",
        name: "Register",
        components: { default: SignIn }
      },
      {
        path: "/signin/forgot-password",
        name: "ForgotPassword",
        components: { default: SignIn }
      },
      {
        path: "/signin/unlock",
        name: "Unlock",
        components: { default: SignIn }
      },
      {
        path: "/signin/reset-password/:id",
        name: "ResetPassword",
        components: { default: SignIn },
        props: true
      },
      {
        path: "/signin/user-unlock/:id",
        name: "UserUnlock",
        components: { default: SignIn },
        props: true
      },
      {
        path: "/signup",
        name: "SignUp",
        components: { default: SignUp }
      },
      {
        path: "/privacy",
        name: "PrivacyPolicy",
        components: { default: PrivacyPolicy }
      },
      {
        path: "/terms",
        name: "TermsAndConditions",
        components: { default: TermsAndConditions }
      },
      {
        path: "/authenticated",
        name: "Authenticated",
        components: { default: AuthSuccess },
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      },
      {
        path: "/activate/:activationToken",
        name: "Activate",
        components: { default: ActivateUser },
        props: true
      },
      {
        path: "/test",
        name: "Test Api",
        components: { default: TestApi }
      }
    ]
  },
  {
    path: "/",
    redirect: "/toolbox",
    component: ToolboxLayout,
    name: "Toolboxs",
    children: [
      {
        path: "toolbox",
        name: "Toolbox",
        components: { default: ToolboxView, header: ToolboxHeader },
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        },
        props: true
      },
      {
        path: "users",
        name: "Users",
        components: { default: UserList, header: DefaultHeader },
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      },
      {
        path: "users/user",
        name: "User",
        components: { default: UserView },
        props: true,
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      },
      {
        path: "groups",
        name: "Groups",
        components: { default: GroupList, header: DefaultHeader },
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      },
      {
        path: "groups/view-group",
        name: "View Group",
        components: { default: GroupReadView },
        props: true,
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      },
      {
        path: "groups/manage-group",
        name: "Manage Group",
        components: { default: GroupEditView },
        props: true,
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      },
      {
        path: "roles",
        name: "Roles",
        components: { default: RoleList, header: DefaultHeader },
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      },
      {
        path: "roles/view-role",
        name: "View Role",
        components: { default: RoleReadView },
        props: true,
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      },
      {
        path: "roles/manage-role",
        name: "Manage Role",
        components: { default: RoleEditView },
        props: true,
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      },
      {
        path: "access-profiles",
        name: "Access Profiles",
        components: { default: AccessProfileList, header: DefaultHeader },
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      },
      {
        path: "access-profiles/bulk-creation",
        name: "Access Profiles Bulk Creation",
        components: {
          default: AccessProfileBulkCreation,
          header: DefaultHeader
        },
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      },
      {
        path: "access-profiles/entitlement-removal",
        name: "Remove Entitlement from Access Profiles",
        components: {
          default: EntitlementRemovalFromAPs,
          header: DefaultHeader
        },
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      },
      {
        path: "tasks/pending",
        name: "Pending Tasks",
        components: { default: PendingTasksList, header: DefaultHeader },
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      },
      {
        path: "tasks/completed",
        name: "Completed Tasks",
        components: { default: CompletedTasksList, header: DefaultHeader },
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      },
      {
        path: "tasks/view-task",
        name: "View Task",
        components: { default: TaskView },
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        },
        props: true
      },
      {
        path: "access-requests",
        name: "Access Requests",
        components: { default: AccessRequestList, header: DefaultHeader },
        meta: {
          requiresAuth: oktaConfig.oktaAuthEnabled
        }
      }
    ]
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: ToolboxLayout,
    redirect: "/user-profile/user",
    children: [
      {
        path: "user",
        name: "User Profile",
        components: { default: UserProfile, header: DefaultHeader },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "avatar",
        name: "Avatar",
        components: { default: Avatar, header: DefaultHeader },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "settings",
        name: "Settings",
        components: { default: Settings, header: DefaultHeader },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "password",
        name: "Password",
        components: { default: Password, header: DefaultHeader },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "claims",
        name: "Claims",
        components: { default: Claims, header: DefaultHeader },
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  { path: "/:pathMatch(.*)*", component: NotFound }
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes
});

// Enable navigation guard
router.beforeEach(navigationGuard);

export default router;
