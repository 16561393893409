<template>
  <div v-if="userProfile" class="row">
    <div class="col-md-4">
      <card-component class="card-user">
        <template #image>
          <div class="image">
            <img src="/img/backgrounds/bg5.jpg" alt="Avatar background" />
          </div>
        </template>
        <div>
          <div class="author">
            <div class="avatar-container">
              <avatar-wrapper
                :size="124"
                :padding-top="10"
                :username="`${userProfile.displayName}`"
                :src="`${userProfile.pictureUrl}`"
              ></avatar-wrapper>
            </div>
            <h5 class="title text-primary">{{ userProfile.displayName }}</h5>
            <p class="description">
              {{ userProfile.login }}
            </p>
          </div>
          <p class="description text-center">
            {{ userProfile.aboutMe }}
          </p>
        </div>
        <template #footer>
          <div class="button-container">
            <basic-button href="#" type="neutral" icon round size="lg">
              <font-awesome-icon
                :icon="['fab', 'facebook-square']"
              ></font-awesome-icon>
            </basic-button>
            <basic-button href="#" type="neutral" icon round size="lg">
              <font-awesome-icon :icon="['fab', 'twitter']"></font-awesome-icon>
            </basic-button>
            <basic-button href="#" type="neutral" icon round size="lg">
              <font-awesome-icon :icon="['fab', 'untappd']"></font-awesome-icon>
            </basic-button>
          </div>
        </template>
      </card-component>
    </div>
    <div class="col-md-8">
      <card-component card-body-classes="table-full-width">
        <template #header>
          <h4 class="card-title">
            <font-awesome-icon
              :icon="['far', 'address-card']"
            ></font-awesome-icon>
            User Profile Attributes
          </h4>
        </template>
        <el-table :data="tableData">
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="column.label"
          ></el-table-column>
        </el-table>
      </card-component>
    </div>
  </div>
</template>
<script>
// COMPONENTS
import AvatarWrapper from "@/components/AvatarWrapper";
import { ElTable, ElTableColumn } from "element-plus";
// VUE INTERNAL
import { ref } from "vue";

export default {
  name: "UserRead",
  components: {
    AvatarWrapper,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn
  },
  props: {
    mode: {
      type: String,
      default: ""
    },
    initialUserProfile: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  setup(props) {
    const userProfile = ref(props.initialUserProfile);

    return {
      userProfile,
      tableColumns: [
        {
          prop: "attribute",
          label: "Attribute",
          minWidth: 200
        },
        {
          prop: "value",
          label: "Value",
          minWidth: 250
        }
      ]
    };
  },
  computed: {
    tableData() {
      return Object.entries(this.initialUserProfile).map((entry) => ({
        attribute: entry[0],
        value: entry[1]
      }));
    }
  },
  methods: {
    tableRowClassName({ rowIndex }) {
      if (rowIndex === 0) {
        return "table-success";
      } else if (rowIndex === 2) {
        return "table-info";
      } else if (rowIndex === 4) {
        return "table-danger";
      } else if (rowIndex === 6) {
        return "table-warning";
      }
      return "";
    },
    cellValueRenderer(row, column, cellValue) {
      let value = cellValue;
      if (typeof row[column.property] === "boolean") {
        value = String(cellValue);
      }
      return value;
    }
  }
};
</script>
<style></style>
