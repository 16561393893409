<template>
  <div class="sidebar" :data-color="backgroundColor">
    <div class="logo">
      <router-link to="/" class="simple-text logo-mini">
        <div class="logo-image">
          <img :src="logo" />
        </div>
      </router-link>

      <a href="https://www.tymezone.nl" class="simple-text logo-normal">
        {{ title }}
      </a>
      <div class="navbar-minimize">
        <button
          id="minimizeSidebar"
          class="btn btn-outline-white btn-icon btn-round"
          @click="minimizeSidebar"
        >
          <font-awesome-icon
            class="visible-on-sidebar-regular"
            :icon="['fas', 'compress-alt']"
            fixed-width
          ></font-awesome-icon>
          <font-awesome-icon
            class="visible-on-sidebar-mini"
            :icon="['fas', 'thumbtack']"
            fixed-width
          ></font-awesome-icon>
        </button>
      </div>
    </div>

    <div class="sidebar-wrapper">
      <ul class="nav">
        <slot name="links">
          <side-bar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <side-bar-item
              v-for="(subLink, i) in link.children"
              :key="subLink.name + i"
              :link="subLink"
            >
            </side-bar-item>
          </side-bar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "SideBar",
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  props: {
    title: {
      type: String,
      default: "Tymezone"
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = [
          "",
          "blue",
          "azure",
          "green",
          "orange",
          "red",
          "purple",
          "black"
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    logo: {
      type: String,
      default: "/img/tymezone-logo.png"
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    }
  }
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
