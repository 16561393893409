<template>
  <nav :id="navbarId" :class="classes" class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <slot></slot>

      <div
        :id="collapseId"
        class="collapse navbar-collapse justify-content-end"
      >
        <ul class="navbar-nav">
          <slot name="navbar-menu"></slot>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "NavBar",
  props: {
    navbarId: {
      type: String,
      default: ""
    },
    collapseId: {
      type: String,
      default: "navbarSupportedContent"
    },
    showNavbar: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: true
    },
    position: {
      type: String,
      default: "absolute"
    },
    colorOnScroll: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info"
        ].includes(value);
      }
    }
  },
  data() {
    return {
      extraNavClasses: "",
      currentScrollValue: 0
    };
  },
  computed: {
    classes() {
      let color = `bg-${this.type}`;
      let navPosition = `navbar-${this.position}`;

      if (this.position === "fixed") {
        navPosition = "fixed-top";
      }

      let colorOnScrollTransparent =
        this.colorOnScroll && this.currentScrollValue < this.colorOnScroll;

      return [
        {
          "navbar-transparent":
            this.transparent && colorOnScrollTransparent && !this.showNavbar
        },
        {
          [color]:
            (!this.transparent && this.colorOnScroll === 0) ||
            this.showNavbar ||
            !this.transparent
        },
        navPosition,
        this.extraNavClasses
      ];
    }
  },
  mounted() {
    document.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `bg-${this.type}`;
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
        }
      }
    }
  }
};
</script>
<style></style>
