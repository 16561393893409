<template>
  <div class="container terms-page">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <card-component class="card-signup" no-footer-line>
          <template #header>
            <h2 class="text-center mb-2">
              <font-awesome-icon :icon="['fas', 'gavel']"></font-awesome-icon>
              Terms &amp; Conditions
            </h2>
          </template>
          <div class="row terms-content">
            <div class="col-lg-3 d-none d-lg-block">
              <scroll-affix
                class="sidebar-menu fs-6 nav flex-column"
                relative-element-selector="#termsandconditions"
                :offset="{ top: 100, bottom: 40 }"
                :enabled="!isMobile"
              >
                <h2 class="mt-0 mb-2">Navigation</h2>
                <a class="nav-link scrollactive-item" href="#top">
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                  ></font-awesome-icon>
                  Back to top
                </a>
                <a class="nav-link scrollactive-item" href="#interpretation">
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                  ></font-awesome-icon>
                  Interpretation and definitions
                </a>
                <a class="nav-link scrollactive-item" href="#acknowledgment">
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                  ></font-awesome-icon>
                  Acknowledgment
                </a>
                <a class="nav-link scrollactive-item" href="#links">
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                  ></font-awesome-icon>
                  Links to other websites
                </a>
                <a class="nav-link scrollactive-item" href="#termination">
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                  ></font-awesome-icon>
                  Termination
                </a>
                <a class="nav-link scrollactive-item" href="#limitation">
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                  ></font-awesome-icon>
                  Limitation of liability
                </a>
                <a class="nav-link scrollactive-item" href="#disclaimer">
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                  ></font-awesome-icon>
                  Disclaimer
                </a>
                <a class="nav-link scrollactive-item" href="#governing">
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                  ></font-awesome-icon>
                  Governing law &amp; disputes
                </a>
                <a class="nav-link scrollactive-item" href="#users">
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                  ></font-awesome-icon>
                  EU &amp; US users
                </a>
                <a class="nav-link scrollactive-item" href="#severability">
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                  ></font-awesome-icon>
                  Severability &amp; waiver
                </a>
                <a class="nav-link scrollactive-item" href="#changes">
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                  ></font-awesome-icon>
                  Terms updates
                </a>
                <a class="nav-link scrollactive-item" href="#contact">
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                  ></font-awesome-icon>
                  Contact us
                </a>
              </scroll-affix>
            </div>
            <div class="col-lg-9">
              <section id="termsandconditions">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="terms"></div>
              </section>
            </div>
          </div>
        </card-component>
      </div>
    </div>
  </div>
</template>
<script>
import ScrollAffix from "@/components/ScrollAffix";

export default {
  name: "TermsAndConditions",
  components: {
    ScrollAffix
  },
  data() {
    return {
      terms: "",
      isMobile: window.innerWidth <= 768
    };
  },
  created() {
    addEventListener("resize", () => {
      this.isMobile = innerWidth <= 768;
    });
  },
  mounted() {
    fetch("./terms.html")
      .then((response) => response.text())
      .then((data) => {
        this.terms = data;
      });
  }
};
</script>
<style></style>
