<template>
  <div class="content">
    <div class="row">
      <suspense>
        <template #default>
          <settings-edit-form />
        </template>
        <template #fallback>
          <loading-animation
            color="primary"
            icon-size="6x"
            fa-library="fas"
            fa-icon="spinner"
          >
          </loading-animation>
        </template>
      </suspense>
    </div>
  </div>
</template>
<script>
// Vue components
import { defineAsyncComponent } from "vue";

export default {
  name: "UserSettings",
  components: {
    SettingsEditForm: defineAsyncComponent(() =>
      import("./components/SettingsEditForm.vue")
    )
  }
};
</script>
<style></style>
