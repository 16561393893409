<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <VeeForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
          <form @submit="handleSubmit($event, removeEntitlement)">
            <card-component no-footer-line card-footer-classes="text-end">
              <template #header>
                <h4 class="card-title">Entitlement Removal</h4>
              </template>
              <template #default>
                <p class="font-size-sm">
                  Here you can remove an entitlement from one or more access
                  profiles. To do so, follow these steps:
                </p>
                <ol>
                  <li>
                    Obtain the ID of the entitlement you want to remove by
                    searching the entitlement by name in the IDN Search GUI (add
                    the ID column in the search results).
                  </li>
                  <li>
                    Get a list of access profiles that contain the entitlement
                    by using either IDN Search GUI or the IDN Search API (don't
                    forget to remove the limit).
                  </li>
                  <li>Enter the ID of the entitlement below.</li>
                  <li>
                    Upload the list of access profiles in a comma separated
                    .CSV-file. Make sure the file has a column named
                    <strong>id</strong> that holds the IDs of the access
                    profiles!
                  </li>
                </ol>
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <h6>
                      <font-awesome-icon
                        :icon="['far', 'envelope']"
                        fixed-width
                      ></font-awesome-icon>
                      Entitlement
                    </h6>
                    <div class="row">
                      <div class="col-md-12">
                        <Field
                          v-slot="{ field }"
                          v-model="entitlementId"
                          name="entitlementId"
                        >
                          <basic-input
                            name="entitlementId"
                            type="text"
                            v-bind="field"
                            label="ID of the entitlement"
                            placeholder="For example: a2e03811f1c34d82bef9c2034816a1a1"
                          >
                          </basic-input>
                        </Field>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <h6 class="mt-3">
                      <font-awesome-icon
                        :icon="['fas', 'file-csv']"
                        fixed-width
                      ></font-awesome-icon>
                      File upload
                    </h6>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label for="locale">Select your csv-file</label>
                          <div class="has-label position-relative">
                            <input
                              type="file"
                              accept=".csv"
                              @change="handleFileUpload($event)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #footer>
                <basic-button native-type="submit" type="primary" class="mb-2">
                  Start removal
                </basic-button>
              </template>
            </card-component>
          </form>
        </VeeForm>
      </div>
      <div class="col-12">
        <card-component card-body-classes="table-full-width" no-footer-line>
          <template #header>
            <h4 class="card-title">Preview</h4>
          </template>
          <div>
            <div class="table-searchbar d-flex flex-wrap mb-2">
              <div class="col-12">
                <label for="pagination">Show</label>
                <el-select
                  v-model="pagination.perPage"
                  class="select-white pagination"
                  name="pagination"
                >
                  <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
                <label>entries </label>

                <div class="float-end"></div>
              </div>
            </div>
            <el-table stripe :data="content.data" table-layout="auto">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.width"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
            </el-table>
          </div>
          <template #footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Showing {{ pagination.perPage }} entries
                </p>
              </div>
            </div>
          </template>
        </card-component>
      </div>
    </div>
    <loading-animation
      v-if="isLoading"
      color="primary"
      icon-size="6x"
      fa-library="fas"
      fa-icon="spinner"
      :current-count="removalCount"
      :total-count="totalCount"
    >
    </loading-animation>
  </div>
</template>
<script>
// DATA
import identityNowApi from "@/api/identityNowApi.js";

// VEE-VALIDATE
import { Form as VeeForm, Field } from "vee-validate";
import { object, string } from "yup";

// VENDOR COMPONENTS
import Papa from "papaparse";
import Swal from "sweetalert2";
import { ElTable, ElTableColumn, ElSelect, ElOption } from "element-plus";

// VUE INTERNAL
import { ref } from "vue";

export default {
  name: "EntitlementRemovalFromAPs",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    VeeForm,
    Field
  },
  setup() {
    const tableData = ref(null);
    const tableColumns = ref([
      {
        prop: "name",
        label: "name",
        width: 200
      },
      {
        prop: "id",
        label: "id",
        width: 180
      },
      {
        prop: "owner/id",
        label: "owner/id",
        width: 180
      },
      {
        prop: "source/id",
        label: "source/id",
        width: 180
      },
      {
        prop: "enabled",
        label: "enabled",
        width: 120
      }
    ]);
    const pagination = ref({
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100, 250],
      total: 500
    });

    const schema = object().shape({
      entitlementId: string().required(
        "Please tell us which entitlement you want to have removed"
      )
    });

    const entitlementId = ref("");
    const file = ref("");
    const content = ref([]);
    const parsed = ref(false);
    const removalCount = ref(0);
    const totalCount = ref(0);
    const isLoading = ref(false);

    return {
      entitlementId,
      file,
      content,
      parsed,
      removalCount,
      totalCount,
      isLoading,
      schema,
      tableData,
      tableColumns,
      pagination
    };
  },

  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
      this.parseFile();
    },
    parseFile() {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          this.content = results;
          this.parsed = true;
        }.bind(this)
      });
    },
    async removeEntitlement() {
      this.isLoading = true;

      this.removalCount = 0;
      this.totalCount = this.content.data.length;

      for (const accessProfile of this.content.data) {
        await identityNowApi.removeEntitlementFromAccessProfile(
          this.entitlementId,
          accessProfile.id
        );
        this.removalCount++;
      }

      Swal.fire({
        icon: "success",
        title: "Success!",
        text:
          "Removed the entitlement from " +
          this.removalCount +
          " access profiles.",
        customClass: {
          confirmButton: "btn btn-success btn-fill"
        },
        buttonsStyling: false
      });

      this.isLoading = false;
    }
  }
};
</script>
<style></style>
