<template>
  <div class="content-with-header">
    <div class="panel-header panel-header-md">
      <div class="header text-center">
        <h2 class="title">{{ roleName }}</h2>
      </div>
    </div>
    <div id="role-edit-page" class="content">
      <div class="row">
        <!-- VIEW MODE -->
        <div class="col-12">
          <div class="row">
            <suspense>
              <template #default>
                <role-read-details :role-id="roleId" />
              </template>
              <template #fallback>
                <loading-animation
                  color="primary"
                  icon-size="6x"
                  fa-library="fas"
                  fa-icon="spinner"
                >
                </loading-animation>
              </template>
            </suspense>
            <suspense>
              <template #default>
                <role-read-members :mode="mode" :role-id="roleId" />
              </template>
              <template #fallback>
                <loading-animation
                  color="primary"
                  icon-size="6x"
                  fa-library="fas"
                  fa-icon="spinner"
                >
                </loading-animation>
              </template>
            </suspense>
          </div>
        </div>
        <!-- VIEW MODE END -->
      </div>
    </div>
  </div>
</template>
<script>
// VUE COMPONENTS
import { defineAsyncComponent } from "vue";

export default {
  name: "RoleReadView",
  components: {
    RoleReadDetails: defineAsyncComponent(() =>
      import("./components/RoleReadDetails.vue")
    ),
    RoleReadMembers: defineAsyncComponent(() =>
      import("./components/RoleReadMembers.vue")
    )
  },

  data() {
    return {
      mode: this.$localStorage.get("mode"),
      roleId: this.$localStorage.get("roleId"),
      roleName: this.$localStorage.get("roleName")
    };
  }
};
</script>
<style></style>
