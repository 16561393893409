// Import internal components
import GlobalComponents from "./globalComponents"; // All Vue app components
import GlobalDirectives from "./globalDirectives"; // A plugin file where you could register global directives
import GlobalIcons from "./globalIcons"; // Font Awesome Icons
import SideBarPlugin from "@/components/SideBarPlugin"; // Sidebar on the right. Used as a local plugin in ToolboxLayout.vue

// External styling
import "element-plus/theme-chalk/index.css"; // Element Plus styling

// Internal styling
import "@/assets/sass/toolbox-theme.scss";

// Asset imports
import "bootstrap"; // Import bootstrap JS
import "@okta/okta-signin-widget"; // Import Okta JS

export default {
  install(app) {
    app.use(GlobalComponents);
    app.use(GlobalDirectives);
    app.use(GlobalIcons);
    app.use(SideBarPlugin);
  }
};
