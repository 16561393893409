<template>
  <VeeForm ref="form" as="div" :validation-schema="schema" @submit="validate">
    <h5 class="info-text">What will this user be doing?</h5>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="row icon-checkbox-container">
          <div class="col-sm-4">
            <icon-checkbox
              v-model="design"
              icon="fa-icon"
              fa-icon="drafting-compass"
              fa-library="fas"
              title="Design"
            >
            </icon-checkbox>
          </div>
          <div class="col-sm-4">
            <icon-checkbox
              v-model="code"
              icon="fa-icon"
              fa-icon="terminal"
              fa-library="fas"
              title="code"
            >
            </icon-checkbox>
          </div>
          <div class="col-sm-4">
            <icon-checkbox
              v-model="develop"
              icon="fa-icon"
              fa-icon="mug-hot"
              fa-library="fas"
              title="Develop"
            >
            </icon-checkbox>
          </div>
        </div>
      </div>
    </div>
  </VeeForm>
</template>
<script>
import IconCheckbox from "@/components/Inputs/IconCheckbox";
import { Form as VeeForm } from "vee-validate";
import { object } from "yup";

export default {
  name: "SecondStep",
  components: {
    IconCheckbox,
    VeeForm
  },
  emits: ["on-validated"],
  setup() {
    const schema = object().shape({
      // No schema required
    });

    return {
      schema
    };
  },
  data() {
    return {
      model: {
        roles: []
      },
      design: false,
      code: false,
      develop: false
    };
  },
  methods: {
    validate() {
      // Store the roles in an Array
      let roles = new Array();
      if (this.design) {
        roles.push("design");
      }
      if (this.code) {
        roles.push("code");
      }
      if (this.develop) {
        roles.push("develop");
      }

      // Update the model
      this.model.roles = roles;

      return this.$refs.form.validate().then((res) => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    }
  }
};
</script>
<style></style>
