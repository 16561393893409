import axios from "axios";
import identityNowAuth from "@/auth/identityNowAuth";

const idnURL = process.env.IDN_CLIENT_API;

// CREATE AXIOS CLIENT
const client = axios.create({
  baseURL: idnURL,
  json: true
});

export default {
  // Create the request by combining the method, the resource end point and the payload
  async execute(method, resource, data) {
    let headers = {};
    // Inject the accessToken from localStorage in the header before each request
    let accessToken = await identityNowAuth.getAccessToken();
    // SailPoint expects a different content-type for patch requests (instead of just application/json)
    if (method === "patch") {
      headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json-patch+json"
      };
    } else {
      headers = { Authorization: `Bearer ${accessToken}` };
    }

    return client({
      method,
      url: resource,
      data,
      headers: headers
    }).then((req) => {
      return req;
    });
  },

  /*
   * ROLE OPERATIONS
   */

  /* ROLE SEARCH OPERATIONS */
  listRoles(limit, offset, count) {
    // Returns a list of roles based on the queryParams
    // The queryParams are passed as a String; params are concatenated with the &-sign
    // Example: "q=Thijmen&limit=3"
    // https://developer.sailpoint.com/apis/beta/#operation/listRoles
    return this.execute(
      "get",
      `/beta/roles?limit=${limit}&offset=${offset}&count=${count}`
    );
  },
  async exportRoles() {
    // Returns a list of roles using the listRoles() method
    // This function steps through the limit of roles until the count has been reached

    let roles = [];
    let count = 0;
    let offset = 0;
    const limit = 50;

    // Count number of roles
    await this.listRoles(1, 0, true).then(
      (response) => (count = response.headers["x-total-count"])
    );

    while (offset < count) {
      await this.listRoles(limit, offset, false).then(
        (response) => (roles = roles.concat(response.data)),
        (offset += limit)
      );
      console.log("Offset is now: " + offset);
    }

    return roles;
  },
  getRole(id) {
    // Gets a single role by ID
    // https://developer.sailpoint.com/apis/beta/#operation/getRole
    return this.execute("get", `/beta/roles/${id}`);
  },
  getRoleAssignedIdentities(id, limit) {
    // Gets a single role by ID
    // https://developer.sailpoint.com/apis/beta/#operation/getRole
    return this.execute(
      "get",
      `/beta/roles/${id}/assigned-identities?limit=${limit}`
    );
  },
  deleteRole(id) {
    // This API deletes a Role by its ID.
    // https://developer.sailpoint.com/apis/beta/#operation/deleteRole
    return this.execute("delete", `/beta/roles/${id}`);
  },

  /*
   * ACCESS PROFILES OPERATIONS
   */

  /* AP SEARCH OPERATIONS */
  listAccessProfiles(limit, offset, count) {
    // Returns a list of access profiles based on the queryParams
    // The queryParams are passed as a String; params are concatenated with the &-sign
    // Example: "q=Thijmen&limit=3"
    // https://developer.sailpoint.com/apis/beta/#operation/listAccessProfiles
    return this.execute(
      "get",
      `/beta/access-profiles?limit=${limit}&offset=${offset}&count=${count}`
    );
  },
  async exportAccessProfiles() {
    // Returns a list of access profiles using the listAccessProfiles() method
    // This function steps through the limit of access profiles until the count has been reached
    let accessProfiles = [];
    let count = 0;
    let offset = 0;
    const limit = 50;

    // Count number of roles
    await this.listAccessProfiles(1, 0, true).then(
      (response) => (count = response.headers["x-total-count"])
    );

    while (offset < count) {
      await this.listAccessProfiles(limit, offset, false).then(
        (response) => (accessProfiles = accessProfiles.concat(response.data)),
        (offset += limit)
      );
      console.log("Offset is now: " + offset);
    }

    return accessProfiles;
  },
  getAccessProfile(id) {
    // Gets a single access profile by its ID
    // https://developer.sailpoint.com/apis/beta/#operation/getAccessProfile
    return this.execute("get", `/beta/access-profiles/${id}`);
  },

  /* AP MANAGEMENT OPERATIONS */
  createAccessProfile(object) {
    // Creates a single access profile
    // https://developer.sailpoint.com/apis/beta/#operation/createAccessProfile
    return this.execute("post", `/beta/access-profiles`, object);
  },
  patchAccessProfile(id, operation, path, value) {
    // This API updates an existing Access Profile. The following fields are patchable:
    // name, description, enabled, owner, requestable, accessRequestConfig, revokeRequestConfig, segments, entitlements, provisioningCriteria
    // https://developer.sailpoint.com/apis/beta/#operation/patchAccessProfile

    // We need to build the payload with the input parameters
    let data = [
      {
        op: operation,
        path: path,
        value: value
      }
    ];

    return this.execute("patch", `/beta/access-profiles/${id}`, data);
  },
  async removeEntitlementFromAccessProfile(entitlementId, accessProfileId) {
    // This function removes an entitlement from an access profile by patching the access profile
    // To do this, we need to:

    // (1) Get the access profiles
    let entitlementList = [];
    await this.getAccessProfile(accessProfileId).then(
      (response) => (entitlementList = response.data.entitlements)
    );
    // (2) Remove the entitlement from the list of entitlements
    for (let [i, entitlement] of entitlementList.entries()) {
      if (entitlement.id === entitlementId) {
        entitlementList.splice(i, 1);
      }
    }
    // (3) Patch the access profile with the updated list of entitlements
    let updatedAccessProfile = [];
    await this.patchAccessProfile(
      accessProfileId,
      "replace",
      "/entitlements",
      entitlementList
    ).then((response) => (updatedAccessProfile = response.data));

    return updatedAccessProfile;
  },

  /*
   * TASK (WORK ITEM) OPERATIONS
   */

  /* TASK SEARCH OPERATIONS */
  listPendingWorkItems(limit, offset, count, ownerId) {
    // This gets a collection of work items belonging to either the specified user(admin required), or the current user.
    // A user id is required!
    // Example: "q=Thijmen&limit=3"
    // https://developer.sailpoint.com/apis/beta/#operation/listRoles

    // If an ownerId is supplied, we add this to the query params
    // We may improve this step by checking if the owner exists
    let ownerParam = "";
    if (ownerId) {
      ownerParam = "&ownerId=" + ownerId;
    }

    return this.execute(
      "get",
      `/v3/work-items?limit=${limit}&offset=${offset}&count=${count}${ownerParam}`
    );
  },
  listCompletedWorkItems(limit, offset, count, ownerId) {
    // This gets a collection of work items belonging to either the specified user(admin required), or the current user.
    // A user id is required!
    // Example: "q=Thijmen&limit=3"
    // https://developer.sailpoint.com/apis/beta/#operation/listRoles

    // If an ownerId is supplied, we add this to the query params
    // We may improve this step by checking if the owner exists
    let ownerParam = "&owner=";
    if (ownerId) {
      ownerParam += ownerId;
    }

    return this.execute(
      "get",
      `/v3/work-items/completed?limit=${limit}&offset=${offset}&count=${count}${ownerParam}`
    );
  },
  getWorkItem(id) {
    // Gets a single access profile by its ID
    // https://developer.sailpoint.com/apis/beta/#operation/getAccessProfile
    return this.execute("get", `/v3/work-items/${id}`);
  },

  /* TASK MANAGEMENT OPERATIONS */
  completeWorkItem(id) {
    // This marks a work item as completed. Either an admin, or the owning/current user must make this request.
    // https://developer.sailpoint.com/apis/beta/#operation/completeWorkItem
    return this.execute("post", `/v3/work-items/${id}`);
  },

  /*
   * ACCESS REQUESTS
   */

  /* ACCESS REQUEST SEARCH OPERATIONS */
  listAccessRequests(limit, offset, count, requestedBy, requestedFor) {
    // Returns a list of access requests based on the queryParams
    // The queryParams are passed as a String; params are concatenated with the &-sign
    // Example: "q=Thijmen&limit=3"
    // https://developer.sailpoint.com/apis/beta/#operation/listAccessProfiles

    // If an ownerId is supplied, we add this to the query params
    // We may improve this step by checking if the owner exists
    let queryParams = "";
    requestedBy ? (queryParams += "&requested-by" + requestedBy) : "";
    requestedFor ? (queryParams += "&requested-for" + requestedFor) : "";

    console.log("Now listing access requests");

    return this.execute(
      "get",
      `/beta/access-request-status?limit=${limit}&offset=${offset}&count=${count}${queryParams}`
    );
  }
};
