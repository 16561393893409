<template>
  <div class="content">
    <div v-if="!isLoading" class="row">
      <div class="col-12">
        <card-component card-header-classes="text-center">
          <template #header>
            <h5 class="card-title">Pick your avatar</h5>
            <basic-button type="primary" @click="onSave()">
              <font-awesome-icon icon="brush" fixed-width></font-awesome-icon>
              Update my avatar!
            </basic-button>
          </template>
          <div class="row d-flex justify-content-center">
            <div class="col-12">
              <image-select
                :data-images="dataImages"
                :selected-images="initialSelected"
                @onselectimage="onSelectImage"
              >
              </image-select>
            </div>
          </div>
        </card-component>
      </div>
    </div>
    <loading-animation
      v-else
      color="primary"
      icon-size="6x"
      fa-library="fas"
      fa-icon="spinner"
    >
    </loading-animation>
  </div>
</template>
<script>
// API & DATA
import avatars from "@/data/avatars.json";
import ImageSelect from "@/components/ImageSelect.vue";
import oktaApi from "@/api/oktaClientApi.js";
// EXTERNAL
import Swal from "sweetalert2";

export default {
  name: "UserAvatar",
  components: {
    ImageSelect
  },
  data() {
    return {
      dataImages: avatars,
      imageSelected: {
        id: "",
        src: "",
        alt: ""
      },
      initialImage: {},
      initialSelected: [],
      isLoading: true,
      user: {
        profile: {
          pictureUrl: ""
        }
      }
    };
  },
  created() {
    // Wait with loading the select-image component until we've collected the initial image
    oktaApi
      .getCurrentUser()
      .then(
        (response) => (
          (this.user.profile.pictureUrl = response.profile.pictureUrl),
          (this.initialImage = this.dataImages.find(
            (el) => el.src === this.user.profile.pictureUrl
          )),
          this.initialSelected.push(this.initialImage),
          (this.isLoading = false)
        )
      );
  },
  methods: {
    onSelectImage: function (data) {
      this.imageSelected = data;
    },
    onSave() {
      try {
        // Save new avatar in user object
        this.user.profile.pictureUrl = this.imageSelected.src;

        // Update user in Okta and then set new avatar in local storage
        oktaApi.updateCurrentUser(this.user).then(
          window.dispatchEvent(
            new CustomEvent("yourAvatar-localstorage-changed", {
              detail: {
                storage: this.user.profile.pictureUrl
              }
            })
          ),

          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "You've updated your avatar!",
            customClass: {
              confirmButton: "btn btn-success btn-fill"
            },
            buttonsStyling: false
          })
        );
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "Your changes could not be saved. Please contact your administrator. Message: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    }
  }
};
</script>
<style></style>
