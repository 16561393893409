<template>
  <div v-if="loggedInUser" class="user user__menu">
    <div class="avatar-container">
      <avatar-wrapper
        :size="40"
        :padding-top="2"
        :username="`${loggedInUser.name}`"
        :src="`${loggedInUser.picture}`"
      ></avatar-wrapper>
    </div>

    <div class="user-menu">
      <a
        data-bs-toggle="collapse"
        href="#userMenu"
        role="button"
        aria-controls="userMenu"
        aria-expanded="false"
      >
        <span>
          {{ loggedInUser.name }}
          <b class="caret"></b>
        </span>
      </a>
      <div id="userMenu" class="collapse">
        <ul class="nav user-menu__nav">
          <slot>
            <li>
              <router-link to="/user-profile/user">
                <font-awesome-icon
                  class="fa-icon"
                  :icon="['fas', 'id-card-alt']"
                  fixed-width
                >
                </font-awesome-icon>
                <p>Profile</p>
              </router-link>
            </li>
            <li>
              <router-link to="/user-profile/avatar">
                <font-awesome-icon
                  class="fa-icon"
                  :icon="['fas', 'camera']"
                  fixed-width
                >
                </font-awesome-icon>

                <p>Avatar</p>
              </router-link>
            </li>
            <li>
              <router-link to="/user-profile/settings">
                <span class="sidebar-mini-icon">
                  <font-awesome-icon
                    class="fa-icon"
                    :icon="['fas', 'user-cog']"
                    fixed-width
                  >
                  </font-awesome-icon>
                </span>
                <p>Settings</p>
              </router-link>
            </li>
            <li>
              <router-link to="/user-profile/password">
                <span class="sidebar-mini-icon">
                  <font-awesome-icon
                    class="fa-icon"
                    :icon="['fas', 'key']"
                    fixed-width
                  >
                  </font-awesome-icon>
                </span>
                <p>Password</p>
              </router-link>
            </li>
            <li>
              <router-link to="/user-profile/claims">
                <span class="sidebar-mini-icon">
                  <font-awesome-icon
                    class="fa-icon"
                    :icon="['fas', 'info']"
                    fixed-width
                  >
                  </font-awesome-icon>
                </span>
                <p>Claims</p>
              </router-link>
            </li>
          </slot>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import AvatarWrapper from "@/components/AvatarWrapper";

export default {
  name: "UserMenu",
  components: {
    AvatarWrapper
  },
  data() {
    return {
      loggedInUser: {
        name: "",
        picture: ""
      }
    };
  },
  async beforeCreate() {
    this.loggedInUser = await this.$auth.getUser();
  },
  mounted() {
    window.addEventListener("yourAvatar-localstorage-changed", (event) => {
      this.loggedInUser.picture = event.detail.storage;
    });
  }
};
</script>
<style>
.user__menu ul.user-menu__nav {
  margin-top: 0;
  padding-top: 20px;
}
</style>
