<template>
  <div
    v-if="(!isLoading && userProfile) || mode === 'create'"
    class="content-with-header"
  >
    <div v-if="mode !== 'create'" class="panel-header panel-header-md">
      <div class="header text-center">
        <h2 class="title">{{ userProfile.displayName }}</h2>
      </div>
    </div>
    <div v-else class="panel-header panel-header-sm"></div>

    <div id="user-edit-page" class="content">
      <div class="row">
        <!-- CREATE MODE -->
        <div v-if="mode === 'create'" class="col-lg-8 me-auto ms-auto">
          <user-create-wizard @create-user-by-child="onCreateUser">
          </user-create-wizard>
        </div>
        <!-- CREATE MODE END -->

        <!-- VIEW MODE -->
        <div v-if="mode === 'read'" class="col-12">
          <user-read :initial-user-profile="userProfile" :mode="mode">
          </user-read>
        </div>
        <!-- VIEW MODE END -->

        <!-- EDIT MODE -->
        <div v-if="mode === 'edit'" class="col-12">
          <card-component>
            <template #header>
              <h4 class="card-title">
                User Management -
                <small class="description">Use the tabs to navigate</small>
              </h4>
            </template>

            <tabs-container v-model:value="tabs.horizontal" type="primary">
              <tab-pane
                id="account"
                label="Account"
                icon="user-circle"
                fa-icon="user-circle"
                fa-library="fas"
                iconclass="tab-icon-horizontal"
              >
                <user-edit-account
                  :user-id="userId"
                  :initial-user-profile="userProfile"
                  :initial-user-status="userStatus"
                  :mode="mode"
                  @update-user-by-child="onUpdateUser"
                  @update-status-by-child="onUpdateStatus"
                >
                </user-edit-account>
              </tab-pane>

              <tab-pane
                id="information"
                label="Information"
                icon="info"
                fa-icon="info"
                fa-library="fas"
                iconclass="tab-icon-horizontal"
              >
                <user-edit-information
                  :initial-user-profile="userProfile"
                  :mode="mode"
                  @update-user-by-child="onUpdateUser"
                ></user-edit-information>
              </tab-pane>

              <tab-pane
                id="social"
                label="Social"
                icon="share-alt"
                fa-icon="share-alt"
                fa-library="fas"
                iconclass="tab-icon-horizontal"
              >
                <user-edit-social
                  :initial-user-profile="userProfile"
                  :mode="mode"
                  @update-user-by-child="onUpdateUser"
                ></user-edit-social>
              </tab-pane>

              <tab-pane
                id="permissions"
                label="Permissions"
                icon="eye"
                fa-icon="eye"
                fa-library="fas"
                iconclass="tab-icon-horizontal"
              >
                <div class="row">
                  <div class="col-lg-6 col-md-8 col-sm-12">
                    <user-edit-permissions
                      :initial-user-profile="userProfile"
                      :mode="mode"
                      @update-user-by-child="onUpdateUser"
                    ></user-edit-permissions>
                  </div>
                </div>
              </tab-pane>
            </tabs-container>
          </card-component>
        </div>
        <!-- EDIT MODE END -->
      </div>
    </div>
  </div>
  <div v-else class="content-with-header">
    <div class="panel-header panel-header-md"></div>
    <loading-animation
      color="primary"
      icon-size="6x"
      fa-library="fas"
      fa-icon="spinner"
    >
    </loading-animation>
  </div>
</template>
<script>
// Tabs
import TabsContainer from "@/components/Tabs/TabsContainer";
import TabPane from "@/components/Tabs/TabPane";

// Subcomponents
import UserRead from "./components/UserRead.vue";
import UserCreateWizard from "./components/UserCreateWizard.vue";
import UserEditAccount from "./components/UserEditAccount.vue";
import UserEditInformation from "./components/UserEditInformation.vue";
import UserEditSocial from "./components/UserEditSocial.vue";
import UserEditPermissions from "./components/UserEditPermissions.vue";

// External components
import Swal from "sweetalert2";

// Data
import oktaApi from "@/api/oktaClientApi.js";

export default {
  name: "UserView",
  components: {
    UserRead,
    UserCreateWizard,
    UserEditAccount,
    UserEditInformation,
    UserEditSocial,
    UserEditPermissions,
    TabPane,
    TabsContainer
  },
  data() {
    return {
      activeName: "first",
      tabs: {
        horizontal: "Account"
      },
      isLoading: false,
      mode: this.$localStorage.get("mode"),
      userId: this.$localStorage.get("userId"),
      userProfile: {},
      userStatus: ""
    };
  },
  async created() {
    if (this.mode === "edit" || this.mode === "read") {
      this.isLoading = true;
      await oktaApi
        .getUser(this.userId)
        .then(
          (response) => (
            (this.userProfile = response.profile),
            (this.userStatus = response.status)
          )
        );
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    onCreateUser(user) {
      // The user was created in the user create wizard
      this.userId = user.id;
      this.userProfile = user.profile;
      this.userStatus = user.status;
      this.mode = "edit";
    },
    onUpdateStatus(userProfile, newStatus) {
      this.userProfile = userProfile;
      this.userStatus = newStatus;
    },
    async onUpdateUser(userProfile) {
      let updatedUser = {
        profile: userProfile
      };
      console.log("userStatus after update is: " + this.userStatus);

      try {
        await oktaApi.updateUser(this.userId, updatedUser);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "You're changes were saved.",
          customClass: {
            confirmButton: "btn btn-success btn-fill"
          },
          buttonsStyling: false
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "Your changes could not be saved. Please contact your administrator. Message: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger"
          },
          buttonsStyling: false
        });
      }
    }
  }
};
</script>
<style></style>
