<template>
  <VeeForm
    v-if="userProfile"
    v-slot="{ handleSubmit }"
    :initial-values="userProfile"
    :validation-schema="schema"
    as="div"
  >
    <form id="user-social-form" @submit="handleSubmit($event, updateUser)">
      <card-component plain no-footer-line card-footer-classes="text-end">
        <template #header>
          <h5>Socials</h5>
        </template>
        <template #default>
          <div class="row">
            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.twitterUrl"
                name="twitterUrl"
              >
                <basic-input
                  v-bind="field"
                  type="text"
                  placeholder="Twitter url"
                  addon-left-icon="fa-icon"
                  fa-icon="twitter"
                  fa-library="fab"
                >
                </basic-input>
              </Field>
            </div>
            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.facebookUrl"
                name="facebookUrl"
              >
                <basic-input
                  v-bind="field"
                  type="text"
                  placeholder="Facebook url"
                  addon-left-icon="fa-icon"
                  fa-icon="facebook"
                  fa-library="fab"
                >
                </basic-input>
              </Field>
            </div>
            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.untappdUrl"
                name="untappdUrl"
              >
                <basic-input
                  v-bind="field"
                  type="text"
                  placeholder="Untappd url"
                  addon-left-icon="fa-icon"
                  fa-icon="untappd"
                  fa-library="fab"
                >
                </basic-input>
              </Field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.linkedinUrl"
                name="linkedinUrl"
              >
                <basic-input
                  v-bind="field"
                  type="text"
                  placeholder="LinkedIn url"
                  addon-left-icon="fa-icon"
                  fa-icon="linkedin"
                  fa-library="fab"
                >
                </basic-input>
              </Field>
            </div>
            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.githubUrl"
                name="githubUrl"
              >
                <basic-input
                  v-bind="field"
                  type="text"
                  placeholder="Github url"
                  addon-left-icon="fa-icon"
                  fa-icon="github"
                  fa-library="fab"
                >
                </basic-input>
              </Field>
            </div>
            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.slackUrl"
                name="slackUrl"
              >
                <basic-input
                  v-bind="field"
                  type="text"
                  placeholder="Slack url"
                  addon-left-icon="fa-icon"
                  fa-icon="slack"
                  fa-library="fab"
                >
                </basic-input>
              </Field>
            </div>
          </div>
        </template>
        <template #footer>
          <basic-button native-type="submit" type="primary"
            >Save changes</basic-button
          >
        </template>
      </card-component>
    </form>
  </VeeForm>
</template>
<script>
// VALIDATION
import { Form as VeeForm, Field } from "vee-validate";
import { object, string } from "yup";
// VUE INTERNAL
import { ref } from "vue";

export default {
  name: "UserEditSocial",
  components: {
    VeeForm,
    Field
  },
  props: {
    mode: {
      type: String,
      default: ""
    },
    initialUserProfile: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  emits: ["update-user-by-child"],
  setup(props) {
    const schema = object().shape({
      twitterUrl: string().url().label("This"),
      facebookUrl: string().url().label("This"),
      untappdUrl: string().url().label("This"),
      linkedinUrl: string().url().label("This"),
      githubUrl: string().url().label("This"),
      slackUrl: string().url().label("This")
    });

    const userProfile = ref(props.initialUserProfile);

    return {
      userProfile,
      schema
    };
  },
  methods: {
    updateUser() {
      this.$emit("update-user-by-child", this.userProfile);
    }
  }
};
</script>
<style></style>
