<template>
  <footer class="footer pb-3">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul class="footer-menu">
          <li>
            <router-link :to="{ path: '/toolbox' }">Toolbox</router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        Copyright &copy; {{ year }} | Created by Thijmen Duijve |
        <a href="https://www.securityme.nl" target="_blank" rel="noopener"
          >securityme</a
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "ContentFooter",
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
