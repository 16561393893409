<template>
  <div
    id="auth-layout"
    class="wrapper d-flex flex-column min-vh-100"
    filter-color="orange"
    :class="pageClass"
  >
    <div
      class="full-page-image"
      style="background-image: url(&quot;img/backgrounds/queenstown.webp&quot;)"
    />

    <main-navbar
      navbar-id="navbar-horizontal"
      position="fixed"
      type="white"
      :color-on-scroll="50"
    ></main-navbar>

    <div class="main-content pt-7">
      <!-- Content pages are inserted here -->
      <router-view></router-view>
      <!-- End of insertion -->
    </div>

    <main-footer
      footer-classes="py-5 mt-auto text-center"
      background-color="transparant"
    ></main-footer>
  </div>
</template>
<script>
import MainNavbar from "@/views/Layout/MainNavbar.vue";
import MainFooter from "@/views/Layout/MainFooter.vue";

export default {
  name: "AuthLayout",
  components: {
    MainNavbar,
    MainFooter
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  data() {
    return {
      year: new Date().getFullYear(),
      pageClass: `${this.$route.name}-page`.toLowerCase()
    };
  },
  watch: {
    $route() {
      this.setPageClass();
    }
  },
  methods: {
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    }
  }
};
</script>
<style lang="scss"></style>
