<template>
  <nav-bar
    :type="type"
    position="absolute"
    collapse-id="navbarSupportedContent"
    :color-on-scroll="colorOnScroll"
    :show-navbar="showMenu"
  >
    <div class="navbar-wrapper">
      <div class="navbar-toggle" :class="{ toggled: showMenu }">
        <nav-bar-toggle-button
          target="#navbarSupportedContent"
          @click="toggleNavbar"
        >
        </nav-bar-toggle-button>
      </div>
      <router-link to="/toolbox" class="navbar-brand">
        Tymezone - Toolbox
      </router-link>
    </div>

    <template #navbar-menu>
      <li v-if="authState && authState.isAuthenticated" class="nav-item">
        <router-link to="/toolbox" class="nav-link">
          <font-awesome-icon
            class="fa-icon d-lg-none"
            :icon="['fas', 'chalkboard']"
          ></font-awesome-icon>
          <span>Toolbox</span>
        </router-link>
      </li>
      <li v-if="authState && !authState.isAuthenticated" class="nav-item">
        <router-link to="/signup" class="nav-link">
          <font-awesome-icon
            class="fa-icon d-lg-none fa-fw"
            :icon="['fas', 'user-plus']"
          ></font-awesome-icon>
          <span>Sign up</span>
        </router-link>
      </li>

      <li v-if="authState && !authState.isAuthenticated" class="nav-item">
        <router-link to="/signin" class="nav-link">
          <font-awesome-icon
            class="fa-icon d-lg-none fa-fw"
            :icon="['fas', 'sign-in-alt']"
          ></font-awesome-icon>
          <span>Sign in</span>
        </router-link>
      </li>

      <li v-if="authState && authState.isAuthenticated" class="nav-item">
        <router-link to="/" class="nav-link" @click="signOut()">
          <span>Sign out</span>
        </router-link>
      </li>

      <li class="nav-item">
        <a class="nav-link btn btn-neutral" href="https://www.tymezone.nl">
          <font-awesome-icon
            class="fa-icon fa-fw"
            :icon="['fas', 'home']"
          ></font-awesome-icon>
          <span>Return To My Tymezone</span>
        </a>
      </li>
    </template>
  </nav-bar>
</template>
<script>
import NavBar from "@/components/NavBar/NavBar";
import NavBarToggleButton from "@/components/NavBar/NavBarToggleButton";

const PATH_TO_HOME_ROUTE = "/";

export default {
  name: "MainNavbar",
  components: {
    NavBar,
    NavBarToggleButton
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  props: {
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info"
        ].includes(value);
      }
    },
    transparent: Boolean,
    colorOnScroll: {
      type: Number,
      default: 50
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250
    };
  },
  beforeUnmount() {
    this.closeMenu();
  },
  methods: {
    async signOut() {
      await this.$auth.signOut();
      // Navigate back to home
      this.$router.push({ path: PATH_TO_HOME_ROUTE });
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    }
  }
};
</script>
<style></style>
