<template>
  <VeeForm
    v-if="userProfile && userStatus"
    v-slot="{ handleSubmit }"
    :initial-values="userProfile"
    :validation-schema="schema"
    as="div"
  >
    <form id="user-account-form" @submit="handleSubmit($event, updateUser)">
      <card-component plain no-footer-line card-footer-classes="text-end">
        <template #default>
          <div class="col-12">
            <div class="avatar-container d-inline-block align-top me-3">
              <avatar-wrapper
                :size="80"
                :padding-top="6"
                :username="`${initialUserProfile.displayName}`"
                :src="`${initialUserProfile.pictureUrl}`"
              ></avatar-wrapper>
            </div>
            <div class="user-actions d-inline-block">
              <h5>{{ initialUserProfile.displayName }}</h5>
              <basic-button
                v-if="
                  initialUserStatus === 'STAGED' ||
                  initialUserStatus === 'DEPROVISIONED'
                "
                native-type="button"
                type="success"
                @click="activateUser"
              >
                <font-awesome-icon
                  fixed-width
                  icon="play-circle"
                ></font-awesome-icon>
                Activate
              </basic-button>
              <basic-button
                v-if="initialUserStatus !== 'DEPROVISIONED'"
                native-type="button"
                type="danger"
                @click="deactivateUser"
              >
                <font-awesome-icon
                  fixed-width
                  icon="power-off"
                ></font-awesome-icon>
                Deactivate
              </basic-button>
              <basic-button
                v-if="initialUserStatus === 'PROVISIONED'"
                native-type="button"
                type="info"
                @click="reactivateUser"
              >
                <font-awesome-icon fixed-width icon="redo"></font-awesome-icon>
                Resend activation link
              </basic-button>
              <basic-button
                v-if="initialUserStatus === 'INACTIVE'"
                native-type="button"
                type="danger"
                @click="deleteUser"
              >
                <font-awesome-icon
                  fixed-width
                  icon="user-times"
                ></font-awesome-icon>
                Delete
              </basic-button>

              <drop-down
                v-if="
                  (initialUserStatus !== 'PROVISIONED') &
                  (initialUserStatus !== 'DEPROVISIONED') &
                  (initialUserStatus !== 'STAGED')
                "
                class="d-inline"
              >
                <template #title>
                  <basic-button type="default" class="dropdown-toggle">
                    More actions
                  </basic-button>
                </template>

                <h6 class="dropdown-header">Actions</h6>

                <a class="dropdown-item" @click="resetPassword">
                  <font-awesome-icon
                    class="me-1"
                    fixed-width
                    icon="key"
                  ></font-awesome-icon>
                  Reset password
                </a>
                <a class="dropdown-item" @click="resetMultifactor">
                  <font-awesome-icon
                    class="me-1"
                    fixed-width
                    icon="fingerprint"
                  ></font-awesome-icon>
                  Reset multifactor
                </a>
                <a
                  v-if="initialUserStatus === 'LOCKED_OUT'"
                  class="dropdown-item"
                  @click="unlockUser"
                >
                  <font-awesome-icon
                    class="me-1"
                    fixed-width
                    icon="unlock"
                  ></font-awesome-icon>
                  Unlock user
                </a>
                <div
                  v-if="
                    initialUserStatus === 'ACTIVE' ||
                    initialUserStatus === 'SUSPENDED'
                  "
                  class="dropdown-divider"
                ></div>
                <a
                  v-if="initialUserStatus === 'ACTIVE'"
                  class="dropdown-item"
                  @click="suspendUser"
                >
                  <font-awesome-icon
                    class="me-1"
                    fixed-width
                    icon="hourglass-start"
                  ></font-awesome-icon>
                  Suspend user
                </a>
                <a
                  v-if="initialUserStatus === 'SUSPENDED'"
                  class="dropdown-item"
                  @click="unsuspendUser"
                >
                  <font-awesome-icon
                    class="me-1"
                    fixed-width
                    icon="forward"
                  ></font-awesome-icon>
                  Unsuspend user
                </a>
              </drop-down>
            </div>
          </div>
          <h6 class="mt-4">
            <font-awesome-icon
              :icon="['fas', 'info']"
              fixed-width
            ></font-awesome-icon>
            Basic info
          </h6>
          <div class="row">
            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.firstName"
                name="firstName"
              >
                <basic-input
                  v-bind="field"
                  type="text"
                  label="First Name *"
                  placeholder="First Name"
                >
                </basic-input>
              </Field>
            </div>
            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.lastName"
                name="lastName"
              >
                <basic-input
                  v-bind="field"
                  type="text"
                  label="Last Name *"
                  placeholder="Last Name"
                >
                </basic-input>
              </Field>
            </div>
            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.displayName"
                name="displayName"
              >
                <basic-input
                  v-bind="field"
                  type="text"
                  label="Display Name"
                  :disabled="true"
                ></basic-input>
              </Field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.email"
                name="email"
              >
                <basic-input
                  v-bind="field"
                  type="email"
                  label="Email *"
                  placeholder="Email"
                >
                </basic-input>
              </Field>
            </div>
            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.login"
                name="login"
              >
                <basic-input
                  v-bind="field"
                  type="email"
                  label="Username (email address)"
                  :disabled="true"
                >
                </basic-input>
              </Field>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="" for="userStatus">Status</label>
                <div class="has-label position-relative">
                  <input
                    id="userStatus"
                    class="form-control"
                    name="userStatus"
                    type="text"
                    :value="userStatus"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="category">* Required fields</div>

          <basic-button native-type="submit" type="primary"
            >Save changes</basic-button
          >
        </template>
      </card-component>
    </form>
  </VeeForm>
</template>
<script>
// API & DATA
import oktaApi from "@/api/oktaClientApi.js";
// VALIDATION
import { Form as VeeForm, Field } from "vee-validate";
import { object, string } from "yup";
// VENDORS
import AvatarWrapper from "@/components/AvatarWrapper";
import Swal from "sweetalert2";
// VUE INTERNAL
import { ref } from "vue";

export default {
  name: "UserEditAccount",
  components: {
    VeeForm,
    Field,
    AvatarWrapper
  },
  props: {
    mode: {
      type: String,
      default: ""
    },
    userId: {
      type: String,
      default: ""
    },
    initialUserProfile: {
      type: Object,
      default: () => {},
      required: true
    },
    initialUserStatus: {
      type: String,
      default: ""
    }
  },
  emits: ["update-user-by-child", "update-status-by-child"],
  setup(props) {
    const schema = object().shape({
      firstName: string().required().label("First name"),
      lastName: string().required().label("Last name"),
      displayName: string(),
      email: string()
        .required()
        .email("The Email field is required and must be a valid email address")
        .label("Email"),
      login: string().required(" ").email(" ").label("")
    });

    const userProfile = ref(props.initialUserProfile);
    const userStatus = ref(props.initialUserStatus);

    return {
      userProfile,
      userStatus,
      schema
    };
  },
  watch: {
    // Calculate displayName based on firstName + lastName
    "userProfile.firstName": function () {
      this.userProfile.displayName =
        this.userProfile.firstName + " " + this.userProfile.lastName;
    },
    "userProfile.lastName": function () {
      this.userProfile.displayName =
        this.userProfile.firstName + " " + this.userProfile.lastName;
    },
    // Calculate login based on email
    "userProfile.email": function () {
      this.userProfile.login = this.userProfile.email;
    }
  },
  methods: {
    updateUser() {
      this.$emit("update-user-by-child", this.userProfile);
    },
    // CREDENTIAL OPERATIONS
    async resetPassword() {
      try {
        await oktaApi.resetPassword(this.userId, true).then(
          (this.userStatus = "RECOVERY"),
          this.$emit(
            "update-status-by-child",
            this.userProfile,
            this.userStatus
          ),
          Swal.fire({
            icon: "success",
            title: "Password reset!",
            text: "The user has received an email with a password reset link.",
            customClass: {
              confirmButton: "btn btn-success btn-fill"
            },
            buttonsStyling: false
          })
        );
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "The user's password could not be reset. Please contact your administrator. Error: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    },
    async resetMultifactor() {
      try {
        await oktaApi.resetMultifactor(this.userId).then(
          (this.userStatus = "ACTIVE"),
          this.$emit(
            "update-status-by-child",
            this.userProfile,
            this.userStatus
          ),
          Swal.fire({
            icon: "success",
            title: "Factors reset!",
            text: "The user needs to set a new factor on login.",
            customClass: {
              confirmButton: "btn btn-success btn-fill"
            },
            buttonsStyling: false
          })
        );
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "The factors could not be reset. Please contact your administrator. Error: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    },
    async unlockUser() {
      try {
        await oktaApi.unlockUser(this.userId).then(
          (this.userStatus = "ACTIVE"),
          this.$emit(
            "update-status-by-child",
            this.userProfile,
            this.userStatus
          ),
          Swal.fire({
            icon: "success",
            title: "User unlocked!",
            text: "The user can login with his/her current password.",
            customClass: {
              confirmButton: "btn btn-success btn-fill"
            },
            buttonsStyling: false
          })
        );
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "The user could not be unlocked. Please contact your administrator. Error: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    },
    // LIFECYCLE OPERATIONS
    async activateUser() {
      try {
        await oktaApi.activateUser(this.userId).then(
          (this.userStatus = "PROVISIONED"),
          this.$emit(
            "update-status-by-child",
            this.userProfile,
            this.userStatus
          ),
          Swal.fire({
            icon: "success",
            title: "User has been invited!",
            text: "The user has received an email with an activation link.",
            customClass: {
              confirmButton: "btn btn-success btn-fill"
            },
            buttonsStyling: false
          })
        );
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "The user could not be activated. Please contact your administrator. Error: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    },
    async deactivateUser() {
      try {
        await oktaApi.deactivateUser(this.userId).then(
          ((this.userProfile = this.initialUserProfile),
          (this.userStatus = "DEPROVISIONED")),
          this.$emit(
            "update-status-by-child",
            this.userProfile,
            this.userStatus
          ),
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "The user has been deactivated.",
            customClass: {
              confirmButton: "btn btn-success btn-fill"
            },
            buttonsStyling: false
          })
        );
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "The user could not be deactivated. Please contact your administrator. Error: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    },
    async reactivateUser() {
      try {
        await oktaApi.reactivateUser(this.userId).then(
          ((this.userProfile = this.initialUserProfile),
          (this.userStatus = "PROVISIONED")),
          this.$emit(
            "update-status-by-child",
            this.userProfile,
            this.userStatus
          ),
          Swal.fire({
            icon: "success",
            title: "User has been invited!",
            text: "The user has received a new email with an activation link!",
            customClass: {
              confirmButton: "btn btn-success btn-fill"
            },
            buttonsStyling: false
          })
        );
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "The user could not be activated. Please contact your administrator. Error: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    },
    deleteUser() {
      Swal.fire({
        title: "Are you sure?",
        text: `You're about to delete ${this.initialUserProfile.displayName}. You won't be able to revert this!`,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-default btn-fill"
        },
        confirmButtonText: "Yes, delete this user!",
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          try {
            oktaApi.deleteUser(this.userId).then(
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: "The user has been deleted!",
                customClass: {
                  confirmButton: "btn btn-success btn-fill"
                },
                buttonsStyling: false
              }).then(
                this.$router.push({
                  name: "Users"
                })
              )
            );
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text:
                "The user could not be activated. Please contact your administrator. Error: " +
                error,
              customClass: {
                confirmButton: "btn btn-danger btn-fill"
              },
              buttonsStyling: false
            });
          }
        }
      });
    },
    async suspendUser() {
      try {
        await oktaApi.suspendUser(this.userId).then(
          ((this.userProfile = this.initialUserProfile),
          (this.userStatus = "SUSPENDED")),
          this.$emit(
            "update-status-by-child",
            this.userProfile,
            this.userStatus
          ),
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "The user has been suspended!",
            customClass: {
              confirmButton: "btn btn-success btn-fill"
            },
            buttonsStyling: false
          })
        );
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "The user could not be suspended. Please contact your administrator. Error: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    },
    async unsuspendUser() {
      try {
        await oktaApi.unsuspendUser(this.userId).then(
          ((this.userProfile = this.initialUserProfile),
          (this.userStatus = "ACTIVE")),
          this.$emit(
            "update-status-by-child",
            this.userProfile,
            this.userStatus
          ),
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "The user has been unsuspended!",
            customClass: {
              confirmButton: "btn btn-success btn-fill"
            },
            buttonsStyling: false
          })
        );
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "The user could not be unsuspended. Please contact your administrator. Message: " +
            error,
          customClass: {
            confirmButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    }
  }
};
</script>
<style></style>
