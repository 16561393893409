<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-stats card-raised">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-primary">
                      <font-awesome-icon
                        :icon="['far', 'comment']"
                        size="2x"
                      ></font-awesome-icon>
                    </div>
                    <h3 class="info-title">
                      <animated-number :value="853"></animated-number>
                    </h3>
                    <h6 class="stats-title">Messages</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-success">
                      <font-awesome-icon
                        :icon="['fas', 'coins']"
                        size="2x"
                      ></font-awesome-icon>
                    </div>
                    <h3 class="info-title">
                      <small>$</small>
                      <animated-number :value="3521"></animated-number>
                    </h3>
                    <h6 class="stats-title">Today Revenue</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-info">
                      <font-awesome-icon
                        :icon="['far', 'handshake']"
                        size="2x"
                      ></font-awesome-icon>
                    </div>
                    <h3 class="info-title">
                      <animated-number :value="562"></animated-number>
                    </h3>
                    <h6 class="stats-title">Customers</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-danger">
                      <font-awesome-icon
                        :icon="['fas', 'headset']"
                        size="2x"
                      ></font-awesome-icon>
                    </div>
                    <h3 class="info-title">
                      <animated-number :value="353"></animated-number>
                    </h3>
                    <h6 class="stats-title">Support Requests</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <card-component class="card-chart" no-footer-line>
          <template #header>
            <div>
              <h5 class="card-category">Active Users</h5>
              <h2 class="card-title">
                <animated-number :value="34252"> </animated-number>
              </h2>
            </div>
          </template>
          <div class="chart-area">
            <canvas :id="activeUsersChartID"></canvas>
          </div>
          <div class="table-responsive">
            <basic-table :data="tableData">
              <template #default="row">
                <td>
                  <div class="flag">
                    <img :src="row.item.flag" />
                  </div>
                </td>
                <td>{{ row.item.country }}</td>
                <td class="text-end">
                  {{ row.item.value }}
                </td>
                <td class="text-end">
                  {{ row.item.percentage }}
                </td>
              </template>
            </basic-table>
          </div>
          <template #footer>
            <div class="stats">
              <font-awesome-icon :icon="['fas', 'redo']"></font-awesome-icon>
              Just Updated
            </div>
          </template>
        </card-component>
      </div>

      <div class="col-lg-4">
        <card-component class="card-chart" no-footer-line>
          <template #header>
            <div>
              <h5 class="card-category">Summer Email Campaign</h5>
              <h2 class="card-title">
                <animated-number :value="55300"> </animated-number>
              </h2>
            </div>
          </template>
          <div class="chart-area">
            <canvas :id="emailsCampaignChartID"></canvas>
          </div>
          <div class="card-progress">
            <progress-bar
              label="Delivery Rate"
              :value="90"
              show-value
            ></progress-bar>
            <progress-bar
              type="success"
              label="Open Rate"
              :value="60"
              show-value
            ></progress-bar>
            <progress-bar
              type="info"
              label="Click Rate"
              :value="12"
              show-value
            ></progress-bar>
            <progress-bar
              type="warning"
              label="Hard Bounce"
              :value="5"
              show-value
            ></progress-bar>
            <progress-bar
              type="danger"
              label="Spam Report"
              :value="0.11"
              show-value
            ></progress-bar>
          </div>
          <template #footer>
            <div class="stats">
              <font-awesome-icon :icon="['fas', 'redo']"></font-awesome-icon>
              Just Updated
            </div>
          </template>
        </card-component>
      </div>

      <div class="col-lg-4">
        <card-component class="card-chart" no-footer-line>
          <template #header>
            <div>
              <h5 class="card-category">Active Countries</h5>
              <h2 class="card-title">
                <animated-number :value="105"> </animated-number>
              </h2>
            </div>
          </template>
          <div class="chart-area">
            <canvas :id="activeCountriesChartID"></canvas>
          </div>

          <template #footer>
            <div class="stats">
              <font-awesome-icon :icon="['fas', 'redo']"></font-awesome-icon>
              Just Updated
            </div>
          </template>
        </card-component>
      </div>
    </div>
  </div>
</template>
<script>
// Components
import AnimatedNumber from "@/components/AnimatedNumber";
import BasicTable from "@/components/Table/BasicTable";
import ProgressBar from "@/components/ProgressBar";

// Charts
import {
  activeUsersChart,
  emailsCampaignChart,
  activeCountriesChart
} from "@/data/Charts/LineChart";

export default {
  name: "ToolboxView",
  components: {
    AnimatedNumber,
    BasicTable,
    ProgressBar
  },
  data() {
    return {
      progress: 0,
      activeUsersChartID: "activeUsersChart",
      emailsCampaignChartID: "emailsCampaignChart",
      activeCountriesChartID: "activeCountriesChart",
      tableData: [
        {
          flag: "img/flags/US.png",
          country: "USA",
          value: "2.920",
          percentage: "53.23%"
        },
        {
          flag: "img/flags/DE.png",
          country: "Germany",
          value: "1.300",
          percentage: "20.43%"
        },
        {
          flag: "img/flags/AU.png",
          country: "Australia",
          value: "760",
          percentage: "10.35%"
        },
        {
          flag: "img/flags/GB.png",
          country: "United Kingdom",
          value: "690",
          percentage: "7.87%"
        },
        {
          flag: "img/flags/RO.png",
          country: "United Kingdom",
          value: "600",
          percentage: "5.94%"
        },
        {
          flag: "img/flags/BR.png",
          country: "Brasil",
          value: "550",
          percentage: "4.34%"
        }
      ],
      productsTable: [
        {
          image: "img/saint-laurent.jpg",
          title: "Suede Biker Jacket",
          subTitle: "by Saint Laurent",
          color: "Black",
          size: "M",
          price: 3390,
          quantity: 1,
          amount: 3390
        },
        {
          image: "img/balmain.jpg",
          title: "Jersey T-Shirt ",
          subTitle: "by Balmain",
          color: "Black",
          size: "M",
          price: 499,
          quantity: 2,
          amount: 998
        },
        {
          image: "img/prada.jpg",
          title: "Slim-Fit Swim Short ",
          subTitle: "by Prada",
          color: "Red",
          size: "M",
          price: 200,
          quantity: 1,
          amount: 200
        }
      ],
      mapData: {
        AU: 760,
        BR: 550,
        CA: 120,
        DE: 1300,
        FR: 540,
        GB: 690,
        GE: 200,
        IN: 200,
        RO: 600,
        RU: 300,
        US: 2920
      }
    };
  },
  mounted() {
    activeUsersChart.createChart(this.activeUsersChartID);
    emailsCampaignChart.createChart(this.emailsCampaignChartID);
    activeCountriesChart.createChart(this.activeCountriesChartID);
  }
};
</script>
<style></style>
