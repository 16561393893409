<template>
  <VeeForm
    v-if="userProfile"
    v-slot="{ handleSubmit }"
    :initial-values="userProfile"
    :validation-schema="schema"
    as="div"
  >
    <form id="user-information-form" @submit="handleSubmit($event, updateUser)">
      <card-component plain no-footer-line card-footer-classes="text-end">
        <template #header>
          <h5>User info</h5>
        </template>
        <template #default>
          <h6>
            <font-awesome-icon
              :icon="['fas', 'user-circle']"
              fixed-width
            ></font-awesome-icon>
            Personal
          </h6>
          <div class="row">
            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.organization"
                name="organization"
              >
                <basic-input
                  v-bind="field"
                  name="organization"
                  type="text"
                  label="Organization"
                  :disabled="true"
                  placeholder="Organization"
                >
                </basic-input>
              </Field>
            </div>

            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.jobTitle"
                name="jobTitle"
              >
                <basic-input
                  v-bind="field"
                  name="jobTitle"
                  type="text"
                  label="Job Title"
                  placeholder="Job Title"
                >
                </basic-input>
              </Field>
            </div>

            <div class="col-md-4">
              <label for="locale">Preferred Language</label>
              <Field
                v-slot="{ value, handleChange }"
                v-model="userProfile.locale"
                name="locale"
              >
                <el-select
                  :model-value="value"
                  class="select-white"
                  filterable
                  @update:model-value="handleChange"
                >
                  <el-option
                    v-for="option in options.languages"
                    :key="option.code"
                    class="select-primary"
                    :label="option.language"
                    :value="option.code"
                  ></el-option>
                </el-select>
              </Field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.mobilePhone"
                name="mobilePhone"
              >
                <basic-input
                  v-bind="field"
                  name="mobilePhone"
                  type="text"
                  label="Mobile"
                  placeholder="Mobile phone number"
                >
                </basic-input>
              </Field>
            </div>

            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.secondEmail"
                name="secondEmail"
              >
                <basic-input
                  v-bind="field"
                  name="secondEmail"
                  type="email"
                  label="Secondary Email"
                  placeholder="Secondary Email"
                >
                </basic-input>
              </Field>
            </div>

            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.websiteUrl"
                name="websiteUrl"
              >
                <basic-input
                  v-bind="field"
                  name="websiteUrl"
                  type="text"
                  label="Website"
                  placeholder="Website URL"
                >
                </basic-input>
              </Field>
            </div>
          </div>

          <h6 class="mt-3">
            <font-awesome-icon
              :icon="['fas', 'map-marker-alt']"
              fixed-width
            ></font-awesome-icon>
            Address
          </h6>

          <div class="row">
            <div class="col-md-8">
              <Field
                v-slot="{ field }"
                v-model="userProfile.streetAddress"
                name="streetAddress"
              >
                <basic-input
                  v-bind="field"
                  name="streetAddress"
                  type="text"
                  label="Address"
                  placeholder="Home Address"
                >
                </basic-input>
              </Field>
            </div>
            <div class="col-md-4">
              <Field
                v-slot="{ field }"
                v-model="userProfile.zipCode"
                name="zipCode"
              >
                <basic-input
                  v-bind="field"
                  name="zipCode"
                  type="text"
                  label="Postal Code"
                  placeholder="XXXX XX"
                >
                </basic-input>
              </Field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <Field v-slot="{ field }" v-model="userProfile.city" name="city">
                <basic-input
                  v-bind="field"
                  name="city"
                  type="text"
                  label="City"
                  placeholder="City"
                >
                </basic-input>
              </Field>
            </div>
            <div class="col-md-6">
              <label for="countryCode">Country</label>
              <Field
                v-slot="{ value, handleChange }"
                v-model="userProfile.countryCode"
                name="countryCode"
              >
                <el-select
                  :model-value="value"
                  class="select-white"
                  filterable
                  @update:model-value="handleChange"
                >
                  <el-option
                    v-for="option in options.countries"
                    :key="option.name"
                    class="select-primary"
                    :label="option.name"
                    :value="option.code"
                  ></el-option>
                </el-select>
              </Field>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="category">* Required fields</div>
          <basic-button native-type="submit" type="primary"
            >Save changes</basic-button
          >
        </template>
      </card-component>
    </form>
  </VeeForm>
</template>
<script>
// DATA
import countries from "@/data/countries.json";
import languages from "@/data/languages.json";
// VALIDATION
import { Form as VeeForm, Field } from "vee-validate";
import { object, string, number } from "yup";
// VENDORS
import { ElSelect, ElOption } from "element-plus";
// VUE INTERNAL
import { ref } from "vue";

export default {
  name: "UserEditInformation",
  components: {
    VeeForm,
    Field,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption
  },
  props: {
    mode: {
      type: String,
      default: ""
    },
    initialUserProfile: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  emits: ["update-user-by-child"],
  setup(props) {
    const schema = object().shape({
      organization: "",
      jobTitle: string(),
      locale: string(),
      mobilePhone: number()
        .nullable(true)
        .transform((v) => (v === "" || Number.isNaN(v) ? null : v))
        .positive()
        .integer()
        .typeError("Please enter numbers only (e.g. 0031612345678)"),
      secondEmail: string().nullable().email("Must be a valid email address"),
      websiteUrl: string().url().label("This"),
      streetAddress: string().label("Street address"),
      zipCode: string().matches(/^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i, {
        message: "Must be a valid postal code e.g. 1234 AB",
        excludeEmptyString: true
      }),
      city: string(),
      countryCode: string()
    });

    const userProfile = ref(props.initialUserProfile);

    return {
      userProfile,
      schema
    };
  },
  data() {
    return {
      options: {
        countries: countries,
        languages: languages
      }
    };
  },
  methods: {
    updateUser() {
      this.$emit("update-user-by-child", this.userProfile);
    }
  }
};
</script>
<style></style>
