// Okta stuff
import OktaSignIn from "@okta/okta-signin-widget";
import oktaConfig from "@/auth/oktaConfig";

const { issuer, clientId, redirectUri, scopes } = oktaConfig.oidc;

const oktaWidget = new OktaSignIn({
  /**
   * Note: when using the Sign-In Widget for an OIDC flow, it still
   * needs to be configured with the base URL for your Okta Org. Here
   * we derive it from the given issuer for convenience.
   */
  useClassicEngine: true, // Not using Okta Identity Engine yet...
  baseUrl: issuer.split("/oauth2")[0],
  clientId,
  redirectUri,
  authParams: {
    issuer,
    scopes,
    display: "page" // Specify how to display the login UI for IDPs, defaults to popup
  },
  // features: https://github.com/okta/okta-signin-widget#feature-flags
  features: {
    registration: true, // Used to enable registration feature on the widget.
    selfServiceUnlock: true, // Enable unlock account feature
    showPasswordToggleOnSignInPage: true,
    idpDiscovery: true, // Enable IDP rerouting, requires IDP routing rule to be setup in Okta
    router: true // Force widget to show routes
  },
  logo: "/img/tymezone-logo-red-text-500px.png",
  logoText: "Tymezone",
  helpSupportNumber: "+31 6 1234 4321",
  brandname: "Tymezone Toolbox",
  // Change the labels and messages of the widget, supports localization
  // See: https://github.com/okta/okta-signin-widget#language-and-text
  i18n: {
    en: {
      "primaryauth.title": "Sign in to the toolbox",
      "primaryauth.username.placeholder": "Your username",
      "primaryauth.password.placeholder": "Your password",
      "password.forgot.emailSent.desc":
        "Email has been sent to {0} with instructions on resetting your password. Didn't receive an e-mail? Tough luck!"
    },
    "nl-NL": {
      "primaryauth.title": "Inloggen op de toolbox",
      "primaryauth.username.placeholder": "Uw gebruikersnaam",
      "primaryauth.password.placeholder": "Uw wachtwoord"
    }
  },
  // Enable idps in combination with feature flags
  idps: [
    { type: "GOOGLE", id: "0oa16jlzy4tzVTUdO0x7" },
    // Custom Identity Provider
    {
      text: "Sign in with Duijve.com",
      className: "social-auth-duijve-button",
      id: "0oa184dhsk8O5Vn1w0x7"
    }
  ]
});

export { oktaWidget };
