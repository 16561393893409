<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card-component
          class="card-table"
          card-body-classes="table-full-width"
          no-footer-line
        >
          <template #header>
            <h4 class="card-title">Users</h4>
          </template>
          <div>
            <div class="col-12 mb-1">
              <basic-button type="primary mt-0" @click="handleCreate()">
                <font-awesome-icon
                  icon="user-plus"
                  fixed-width
                ></font-awesome-icon>
                Create User
              </basic-button>
            </div>
            <div class="table-searchbar d-flex flex-wrap">
              <div class="col-12">
                <label for="pagination">Show</label>

                <el-select
                  v-model="pagination.perPage"
                  class="select-white pagination"
                  name="pagination"
                >
                  <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>

                <label>entries</label>

                <div class="float-end ms-3">
                  <Field
                    v-slot="{ field }"
                    v-model="searchQuery"
                    name="searchQuery"
                  >
                    <basic-input
                      v-bind="field"
                      name="searchQuery"
                      type="text"
                      placeholder="Search..."
                      addon-left-icon="fa-icon fa-fw"
                      fa-icon="search"
                      fa-library="fas"
                    />
                  </Field>
                </div>
                <div class="float-end">
                  <el-select
                    v-model="searchStatus"
                    class="select-white"
                    name="status"
                    placeholder="Filter by status"
                    clearable
                  >
                    <el-option
                      v-for="option in options.status"
                      :key="option.status"
                      class="select-primary"
                      :label="option.label"
                      :value="option.status"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <el-table
              v-if="!isLoading"
              stripe
              style="width: 100%"
              :data="tableData"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="135" fixed="right" label="Actions">
                <template #default="props">
                  <div class="table-actions">
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="View"
                      placement="top-start"
                    >
                      <basic-button
                        class="view"
                        type="info"
                        size="sm"
                        round
                        icon
                        @click="handleView(props.$index, props.row)"
                      >
                        <font-awesome-icon
                          icon="eye"
                          fixed-width
                        ></font-awesome-icon>
                      </basic-button>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="Edit"
                      placement="top-start"
                    >
                      <basic-button
                        class="edit"
                        type="warning"
                        size="sm"
                        round
                        icon
                        @click="handleEdit(props.$index, props.row)"
                      >
                        <font-awesome-icon
                          icon="wrench"
                          fixed-width
                        ></font-awesome-icon>
                      </basic-button>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="Delete"
                      placement="top-start"
                    >
                      <basic-button
                        class="remove"
                        type="danger"
                        size="sm"
                        round
                        icon
                        @click="handleDelete(props.$index, props.row)"
                      >
                        <font-awesome-icon
                          icon="trash-alt"
                          fixed-width
                        ></font-awesome-icon>
                      </basic-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <loading-animation
              v-else
              color="primary"
              icon-size="6x"
              fa-library="fas"
              fa-icon="spinner"
            >
            </loading-animation>
          </div>
          <template #footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Showing {{ pagination.perPage }} entries
                </p>
              </div>
            </div>
          </template>
        </card-component>
      </div>
    </div>
  </div>
</template>
<script>
// Data
import status from "@/data/oktaStatus.json";
import { Field } from "vee-validate";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElTooltip
} from "element-plus";
import oktaApi from "@/api/oktaClientApi.js";
import Swal from "sweetalert2";

export default {
  name: "UserList",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    Field,
    ElTooltip
  },
  localStorage: {
    mode: {
      type: String,
      default: ""
    },
    groupId: {
      type: String,
      default: ""
    },
    groupName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isLoading: true,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100, 200]
      },
      searchQuery: "",
      options: {
        status: status
      },
      searchStatus: "",
      tableColumns: [
        {
          prop: "profile.firstName",
          label: "First Name",
          minWidth: 150
        },
        {
          prop: "profile.lastName",
          label: "Last Name",
          minWidth: 150
        },
        {
          prop: "profile.email",
          label: "Email",
          minWidth: 250
        },
        {
          prop: "profile.organization",
          label: "Organization",
          minWidth: 250
        },
        {
          prop: "status",
          label: "Status",
          minWidth: 150
        }
      ],
      tableData: []
    };
  },
  watch: {
    "pagination.perPage": function () {
      oktaApi
        .getUsers(this.pagination.perPage)
        .then((response) => (this.tableData = response));
    },
    searchQuery: function () {
      oktaApi.findUsers(this.searchQuery, 10).then((response) => {
        this.tableData = response;
      });
    },
    searchStatus: function () {
      if (this.searchStatus !== "") {
        oktaApi
          .filterUsers('status eq "' + this.searchStatus + '"', 100)
          .then((response) => {
            this.tableData = response;
          });
      } else {
        oktaApi
          .getUsers(this.pagination.perPage)
          .then((response) => (this.tableData = response));
      }
    }
  },
  async created() {
    // Get users and load tableData with default pagination
    this.isLoading = true;
    await oktaApi
      .getUsers(this.pagination.perPage)
      .then((response) => (this.tableData = response));
    this.isLoading = false;
  },

  methods: {
    handleCreate() {
      this.$localStorage.set("mode", "create");
      this.$localStorage.set("userId", "");

      this.$router.push({
        name: "User"
      });
    },
    handleEdit(index, row) {
      this.$localStorage.set("mode", "edit");
      this.$localStorage.set("userId", `${row.id}`);

      this.$router.push({
        name: "User"
      });
    },
    handleView(index, row) {
      this.$localStorage.set("mode", "read");
      this.$localStorage.set("userId", `${row.id}`);

      this.$router.push({
        name: "User"
      });
    },
    handleDelete(index, row) {
      Swal.fire({
        title: "Are you sure?",
        text: `You're about to delete ${row.profile.displayName}. You won't be able to revert this!`,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-default btn-fill"
        },
        confirmButtonText: "Yes, delete this user!",
        buttonsStyling: false
      }).then((result) => {
        // If confirmed, delete the user in Okta and remove from table
        if (result.value) {
          //  TODOOOO: Deactivate before deletion

          // delete the user in Okta
          oktaApi.deleteUser(`${row.id}`);
          // Delete the user from the table
          let indexToDelete = this.tableData.findIndex(
            (tableRow) => tableRow.id === row.id
          );
          if (indexToDelete >= 0) {
            this.tableData.splice(indexToDelete, 1);
          }
        }
      });
    }
  }
};
</script>
<style></style>
