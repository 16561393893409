// This file is used for communicating with the Okta API through the Okta SDK.
// The Okta SDK is installed on a separate Express.js server component (in the backend dir).
// This server components functions as a middleware component and runs on the same machine which means that is can be reached on localhost (port 3000).
//
// A request follows the path below:
// 1. The Vue app (client) wants to communicate with Okta and initiates a request to the Express middleware component (server)
// 2. The server uses the Okta SDK to send the request to the Okta API
// 3. The server receive the response from the Okta API and it is processed by the Okta SDK
// 4. The processed response is send back to the Vue app (client) by the Express middleware component (server)
// 5. Done

import axios from "axios";

export default {
  // CREATE THE REQUEST BY COMBINING THE OPERATION AND THE DATA
  async execute(method, resource, data) {
    // CREATE AXIOS CLIENT
    const client = axios.create({
      baseURL: "http://localhost:3000/",
      json: true
    });

    return client({
      method,
      url: resource,
      data
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  },

  /*
   * USER OPERATIONS
   */

  getUser(userId) {
    // Gets a single user by Id or Login
    // https://developer.okta.com/docs/reference/api/users/#get-user
    return this.execute("get", `/api/get-user/?userId=${userId}`);
  },

  getUsers(queryParams) {
    // Returns a list of users based on the queryParams
    // The queryParams are passed as a String; params are concatenated with the &-sign
    // Example: "q=Thijmen&limit=3"
    // https://developer.okta.com/docs/reference/api/users/#list-users-with-a-filter
    return this.execute("get", `/api/get-users?${queryParams}`);
  },

  createUser(queryParams, data) {
    // Creates a user derived from the data. The activation method is determined in the queryParams.
    // The queryParams are passed as a String; params are concatenated with the &-sign
    // Example: "activation=true&nextLogin=changePassword"
    // https://developer.okta.com/docs/reference/api/users/#create-user
    return this.execute("post", `/api/create-user?${queryParams}`, data);
  },

  updateUser(userId, data) {
    // Updates the user's profile of the provided userId
    // We use the POST method to make partial updates
    // https://developer.okta.com/docs/reference/api/users/#update-user
    return this.execute("post", `/api/update-user/?userId=${userId}`, data);
  },

  deleteUser(userId, notifyAdmin) {
    // Deletes a user permanently and cannot be recovered!
    // This operation can only be performed on users that have a DEPROVISIONED status.
    // This operation on a user that hasn't been deactivated causes that user to be deactivated.
    // A second delete operation is required to delete the user.
    return this.execute(
      "get",
      `/api/delete-user/?userId=${userId}&sendEmail=${notifyAdmin}`
    );
  },

  /*
   * LIFECYCLE OPERATIONS
   */

  activateUser(userId, sendEmailInvitation) {
    // This operation can only be performed on users with a STAGED or DEPROVISIONED status.
    // Activation of a user is an asynchronous operation.
    return this.execute(
      "get",
      `/api/activate-user/?userId=${userId}&sendEmail=${sendEmailInvitation}`
    );
  },

  deactivateUser(userId, notifyAdminByEmail) {
    return this.execute(
      "get",
      `/api/deactivate-user/?userId=${userId}&sendEmail=${notifyAdminByEmail}`
    );
  },

  reactivateUser(userId, notifyAdminByEmail) {
    return this.execute(
      "get",
      `/api/reactivate-user/?userId=${userId}&sendEmail=${notifyAdminByEmail}`
    );
  },

  suspendUser(userId) {
    return this.execute("get", `/api/suspend-user/?userId=${userId}`);
  },

  unsuspendUser(userId) {
    return this.execute("get", `/api/unsuspend-user/?userId=${userId}`);
  },

  unlockUser(userId) {
    return this.execute("post", `/api/v1/users/${userId}/lifecycle/unlock`);
  },

  /*
   * CREDENTIAL OPERATIONS
   */

  changePassword(userId, data) {
    // Changes a user's password by validating the user's current password
    // https://developer.okta.com/docs/reference/api/users/#change-password
    return this.execute("post", `/api/change-password/?userId=${userId}`, data);
  },

  resetPassword(userId, sendEmail) {
    // Resets the user's password and returns a OTT to the user by email or to the client (Vue app)
    return this.execute(
      "get",
      `/api/reset-password/?userId=${userId}&sendEmail=${sendEmail}`
    );
  },

  resetFactors(userId) {
    return this.execute("get", `/api/reset-factors/?userId=${userId}`);
  },

  /*
   * GROUP OPERATIONS
   */

  getGroup(groupId) {
    // Gets a single group by Id
    return this.execute("get", `/api/get-group/?groupId=${groupId}`);
  },

  getGroups(queryParams) {
    // Returns a list of groups based on the queryParams
    // The queryParams are passed as a String; params are concatenated with the &-sign
    // Example: "q=Okta&limit=3"
    // https://developer.okta.com/docs/reference/api/groups/#list-groups
    return this.execute("get", `/api/get-groups?${queryParams}`);
  },

  createGroup(data) {
    // Adds a new group with `OKTA_GROUP` type to the org
    return this.execute("post", "/api/create-group", data);
  },

  updateGroup(groupId, data) {
    return this.execute("post", `/api/update-group/?groupId=${groupId}`, data);
  },

  deleteGroup(groupId) {
    return this.execute("get", `/api/delete-group/?groupId=${groupId}`);
  },

  /*
   * GROUP MEMBER OPERATIONS
   */

  listGroupUsers(groupId, queryParams) {
    // Returns a list of users in a group, based on the queryParams
    // The queryParams are passed as a String; params are concatenated with the &-sign
    // Example: "limit=3"
    // https://developer.okta.com/docs/reference/api/groups/#list-group-members
    return this.execute(
      "get",
      `/api/list-group-users/?groupId=${groupId}&${queryParams}`
    );
  },

  addUserToGroup(groupId, userId) {
    return this.execute(
      "get",
      `/api/add-user-to-group/?groupId=${groupId}&userId=${userId}`
    );
  },

  removeUserFromGroup(groupId, userId) {
    return this.execute(
      "get",
      `/api/remove-user-from-group/?groupId=${groupId}&userId=${userId}`
    );
  }
};
