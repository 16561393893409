<template>
  <div class="panel-header panel-header-lg">
    <canvas :id="headerChartID"></canvas>
  </div>
</template>

<script>
import { headerChart } from "@/data/Charts/HeaderChart";

export default {
  name: "OverviewHeader",
  data() {
    return {
      headerChartID: "headerChart"
    };
  },
  mounted() {
    headerChart.createChart(this.headerChartID);
  }
};
</script>

<style scoped></style>
